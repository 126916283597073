/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
export type JSONPrimitive = string | number | boolean | null
export type JSONValue = JSONPrimitive | JSONObject | JSONArray
export type JSONArray = JSONValue[]
export type JSONObject = { [member: string]: JSONValue }
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigInt: { input: number; output: number }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: string; output: Date }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date; output: Date }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: JSONValue; output: JSONValue }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: JSONObject; output: JSONObject }
  Void: { input: null; output: null }
  _Any: { input: any; output: any }
  federation__FieldSet: { input: any; output: any }
  link__Import: { input: any; output: any }
}

export enum AccessLevel {
  CareCoordinator = 'CARE_COORDINATOR',
  Coach = 'COACH',
  CommunityFacilitator = 'COMMUNITY_FACILITATOR',
  Manager = 'MANAGER',
}

export type AddTagToPatientAction = {
  __typename?: 'AddTagToPatientAction'
  tagID: Scalars['ID']['output']
  uid: Scalars['ID']['output']
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type AfterAppLoadGetStartedScreen = {
  __typename?: 'AfterAppLoadGetStartedScreen'
  success?: Maybe<Scalars['Boolean']['output']>
}

export type AfterAppLoadScreen = AfterAppLoadGetStartedScreen | AfterAppLoadSignupFormScreen

export type AfterAppLoadSignupFormScreen = {
  __typename?: 'AfterAppLoadSignupFormScreen'
  form: SignupForm
}

export type AllPaymentsCompleted = {
  __typename?: 'AllPaymentsCompleted'
  badgeCopy?: Maybe<Scalars['String']['output']>
  copy?: Maybe<Scalars['String']['output']>
  iconName?: Maybe<Scalars['String']['output']>
}

export type AnswerInAppRatingResult = {
  __typename?: 'AnswerInAppRatingResult'
  rating: InAppRating
}

export type AperoInvoice = {
  __typename?: 'AperoInvoice'
  amountCharged: Scalars['String']['output']
  dueDate: Scalars['Date']['output']
  id: Scalars['String']['output']
}

export type AperoPaymentInformation = {
  __typename?: 'AperoPaymentInformation'
  id?: Maybe<Scalars['String']['output']>
  invoices?: Maybe<Array<AperoInvoice>>
}

export type AppStoreReviewPromptStatus = {
  __typename?: 'AppStoreReviewPromptStatus'
  shouldPromptReview: Scalars['Boolean']['output']
}

export enum AppUpdateStatus {
  UpdateRecommended = 'UPDATE_RECOMMENDED',
  UpdateRequired = 'UPDATE_REQUIRED',
  UpToDate = 'UP_TO_DATE',
}

export type AutoOpeningLesson = {
  __typename?: 'AutoOpeningLesson'
  lessonID?: Maybe<Scalars['ID']['output']>
  responseIDToResume?: Maybe<Scalars['ID']['output']>
}

export type AvailableVideoCallSlot = {
  __typename?: 'AvailableVideoCallSlot'
  availabilityCalendarEventID: Scalars['String']['output']
  coach: CoachProfile
  coachInfoModalEnabled?: Maybe<Scalars['Boolean']['output']>
  coachInfoModalTitle?: Maybe<Scalars['String']['output']>
  end: Scalars['DateTime']['output']
  start: Scalars['DateTime']['output']
}

export type AvailableVideoCallSlots = {
  __typename?: 'AvailableVideoCallSlots'
  callType?: Maybe<VideoCallType>
  slots: Array<AvailableVideoCallSlot>
}

export type AvailableVideoCallSlotsResult = AvailableVideoCallSlots | VideoCall

export type BillingContact = {
  __typename?: 'BillingContact'
  billingID: Scalars['String']['output']
  birthDate?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  employer?: Maybe<Scalars['String']['output']>
  firstname: Scalars['String']['output']
  insuranceSubscriber: BillingContactInsuranceSubscriber
  lastname: Scalars['String']['output']
  source?: Maybe<Scalars['String']['output']>
}

export type BillingContactInsuranceSubscriber = {
  __typename?: 'BillingContactInsuranceSubscriber'
  birthDate?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  memberID?: Maybe<Scalars['String']['output']>
}

export type CancelPlanForm = CancelPlanScreenReasonsToCancel | CancelPlanScreenUserFeedback

export type CancelPlanResult = {
  __typename?: 'CancelPlanResult'
  success: Scalars['Boolean']['output']
}

export type CancelPlanScreenReasonsToCancel = {
  __typename?: 'CancelPlanScreenReasonsToCancel'
  items?: Maybe<Array<CancelPlanScreenReasonsToCancelItem>>
  label?: Maybe<Scalars['String']['output']>
  subTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type CancelPlanScreenReasonsToCancelItem = {
  __typename?: 'CancelPlanScreenReasonsToCancelItem'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type CancelPlanScreenUserFeedback = {
  __typename?: 'CancelPlanScreenUserFeedback'
  label?: Maybe<Scalars['String']['output']>
  placeholder?: Maybe<Scalars['String']['output']>
  subTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type CancelVideoCallByParticipantResult = InAppVideoCallResult

export enum CancellationReasons {
  LifeEvent = 'LIFE_EVENT',
  MetGoalAndFeelBetter = 'MET_GOAL_AND_FEEL_BETTER',
  NotMatchWithTherapist = 'NOT_MATCH_WITH_THERAPIST',
  OtherReason = 'OTHER_REASON',
  ProgramNotWorthTheCost = 'PROGRAM_NOT_WORTH_THE_COST',
  SeekingOtherSupport = 'SEEKING_OTHER_SUPPORT',
  TooExpensive = 'TOO_EXPENSIVE',
}

export type CareTeamMember = {
  __typename?: 'CareTeamMember'
  coachID: Scalars['ID']['output']
  isPrimary: Scalars['Boolean']['output']
}

export type ChatMessage = {
  __typename?: 'ChatMessage'
  coachID?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  direction: ChatMessageDirection
  feedID: Scalars['ID']['output']
  linkedResponse?: Maybe<PatientResponse>
  linksToResponseID?: Maybe<Scalars['ID']['output']>
  messageID: Scalars['ID']['output']
  text: Scalars['String']['output']
}

export type ChatMessageCreated = {
  __typename?: 'ChatMessageCreated'
  chatMessage?: Maybe<ChatMessage>
}

export enum ChatMessageDirection {
  ToCoach = 'TO_COACH',
  ToPatient = 'TO_PATIENT',
}

export type ChatMessageEdited = {
  __typename?: 'ChatMessageEdited'
  chatMessage: ChatMessage
}

export enum ChatMessageLinkType {
  Response = 'RESPONSE',
}

export type ChatMessageLinksToInput = {
  id: Scalars['ID']['input']
  type: ChatMessageLinkType
}

export type ChatMessagesResult = {
  __typename?: 'ChatMessagesResult'
  chatMessages: Array<ChatMessage>
}

export type CheckAppVersionResult = {
  __typename?: 'CheckAppVersionResult'
  description?: Maybe<Scalars['String']['output']>
  linkLabel?: Maybe<Scalars['String']['output']>
  linkToUpdate?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updateStatus: AppUpdateStatus
}

export enum ClientOs {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export type CoachAvailability = {
  __typename?: 'CoachAvailability'
  coachProfiles?: Maybe<Array<CoachProfile>>
  videoCallSlots?: Maybe<Array<VideoCallSlot>>
}

export type CoachAvailabilityResult = CoachAvailability | VideoCall

export type CoachProfile = {
  __typename?: 'CoachProfile'
  description?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastname?: Maybe<Scalars['String']['output']>
  photoUrl?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  videoUrlSD?: Maybe<Scalars['String']['output']>
}

export type CommonPatientHistory = PatientHistory & {
  __typename?: 'CommonPatientHistory'
  coachID?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  date?: Maybe<Scalars['String']['output']>
  entryID: Scalars['ID']['output']
  source?: Maybe<Scalars['String']['output']>
  type?: Maybe<PatientHistoryType>
  value?: Maybe<Scalars['JSONObject']['output']>
}

/** The community type */
export type Community = {
  __typename?: 'Community'
  communityID: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  name: Scalars['String']['output']
  opensAt: Scalars['DateTime']['output']
}

export type CommunityFacilitator = {
  __typename?: 'CommunityFacilitator'
  name: Scalars['String']['output']
}

/** Post to the community */
export type CommunityPost = {
  community: Community
  communityID: Scalars['ID']['output']
  communityPostReplies: CommunityPostReplies
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  label: Scalars['String']['output']
  postID: Scalars['ID']['output']
  publishedAt: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

/** Post to the community */
export type CommunityPostCommunityPostRepliesArgs = {
  showHidden?: InputMaybe<Scalars['Boolean']['input']>
}

/** Community post was created */
export type CommunityPostCreated = {
  __typename?: 'CommunityPostCreated'
  communityPost: CommunityPost
}

/** Input specific for type of community post */
export type CommunityPostInput = {
  question?: InputMaybe<CommunityPostQuestionInput>
}

/** Question type post to the community */
export type CommunityPostQuestion = CommunityPost & {
  __typename?: 'CommunityPostQuestion'
  community: Community
  communityID: Scalars['ID']['output']
  communityPostReplies: CommunityPostReplies
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  label: Scalars['String']['output']
  postID: Scalars['ID']['output']
  publishedAt: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

/** Question type post to the community */
export type CommunityPostQuestionCommunityPostRepliesArgs = {
  showHidden?: InputMaybe<Scalars['Boolean']['input']>
}

/** Question type post contents */
export type CommunityPostQuestionInput = {
  content?: InputMaybe<Scalars['String']['input']>
}

/** Collection of community post replies with related metadata */
export type CommunityPostReplies = {
  __typename?: 'CommunityPostReplies'
  canReplyToPost: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  numberOfPatientsReplied: Scalars['Int']['output']
  repliedMyself: Scalars['Boolean']['output']
  replies?: Maybe<Array<CommunityPostReply>>
  repliesHiddenReason?: Maybe<Scalars['String']['output']>
  replyingClosedAt?: Maybe<Scalars['DateTime']['output']>
  replyingStatus: Scalars['String']['output']
}

/** Reply to community post */
export type CommunityPostReply = {
  createdAt: Scalars['DateTime']['output']
  isHidden: Scalars['Boolean']['output']
  isMyReply: Scalars['Boolean']['output']
  postID: Scalars['ID']['output']
  replyID: Scalars['ID']['output']
}

export type CommunityPostReplyAuthor = CommunityFacilitator

/** Hidden community post reply */
export type CommunityPostReplyHidden = {
  __typename?: 'CommunityPostReplyHidden'
  reply: CommunityPostReply
}

/**
 * Input parameters for replying to community post
 *
 * Should contain one of the propeties
 */
export type CommunityPostReplyInput = {
  questionAnswer?: InputMaybe<CommunityPostReplyInputQuestionAnswer>
}

/** Answering to question */
export type CommunityPostReplyInputQuestionAnswer = {
  message: Scalars['String']['input']
}

/** Answer to community question */
export type CommunityPostReplyQuestionAnswer = CommunityPostReply & {
  __typename?: 'CommunityPostReplyQuestionAnswer'
  createdAt: Scalars['DateTime']['output']
  isHidden: Scalars['Boolean']['output']
  isMyReply: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  postID: Scalars['ID']['output']
  replyAuthor?: Maybe<CommunityPostReplyAuthor>
  replyID: Scalars['ID']['output']
}

/** Result of reply sending */
export type CommunityPostReplyResult = CommunityPostReplySended | CommunityPostReplySendingPrevented

/** Reply was sended successfully */
export type CommunityPostReplySended = {
  __typename?: 'CommunityPostReplySended'
  communityPost: CommunityPost
  reply: CommunityPostReply
}

/** Reply sending was prevented */
export type CommunityPostReplySendingPrevented = {
  __typename?: 'CommunityPostReplySendingPrevented'
  communityPost: CommunityPost
  reason: Scalars['String']['output']
}

/** Restored community post reply */
export type CommunityPostReplyUnhidden = {
  __typename?: 'CommunityPostReplyUnhidden'
  reply: CommunityPostReply
}

/** Community posts result */
export type CommunityPostsResult = {
  __typename?: 'CommunityPostsResult'
  posts: Array<CommunityPost>
}

export type CostEstimate = {
  __typename?: 'CostEstimate'
  intakeCostEstimateInCents?: Maybe<Scalars['Int']['output']>
  month1CostEstimateInCents?: Maybe<Scalars['Int']['output']>
  month2CostEstimateInCents?: Maybe<Scalars['Int']['output']>
  month3CostEstimateInCents?: Maybe<Scalars['Int']['output']>
  programCostEstimateInCents?: Maybe<Scalars['Int']['output']>
}

export type CostEstimateLookup = CostEstimateLookupFailure | CostEstimateLookupSuccess

export type CostEstimateLookupFailure = {
  __typename?: 'CostEstimateLookupFailure'
  reason: Scalars['String']['output']
}

export type CostEstimateLookupSuccess = {
  __typename?: 'CostEstimateLookupSuccess'
  intakeCostEstimateInCents: Scalars['Int']['output']
  month1CostEstimateInCents: Scalars['Int']['output']
  month2CostEstimateInCents: Scalars['Int']['output']
  month3CostEstimateInCents: Scalars['Int']['output']
}

export type CreateChatMessageResult = ChatMessageCreated

export type CreateEnrollmentAction = {
  __typename?: 'CreateEnrollmentAction'
  patient: Patient
  uid: Scalars['ID']['output']
}

export type CreateSmsMessageResult = {
  __typename?: 'CreateSmsMessageResult'
  smsMessage?: Maybe<SmsMessage>
}

export type CustomQuestionInput = {
  answer?: InputMaybe<Scalars['String']['input']>
  question: Scalars['String']['input']
  questionID: Scalars['String']['input']
}

export type CustomerIoNotificationOpenInput = {
  deliveryID: Scalars['String']['input']
  deviceID: Scalars['String']['input']
}

export enum DevicePlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export type EditChatMessageResult = ChatMessageEdited

export type Email = {
  __typename?: 'Email'
  createdAt: Scalars['DateTime']['output']
  emailID: Scalars['ID']['output']
  from?: Maybe<Scalars['String']['output']>
  htmlBody?: Maybe<Scalars['String']['output']>
  status: EmailStatus
  statusChangedAt: Scalars['DateTime']['output']
  subject?: Maybe<Scalars['String']['output']>
  textBody?: Maybe<Scalars['String']['output']>
  to: Scalars['String']['output']
}

export enum EmailStatus {
  Bounced = 'BOUNCED',
  Delivered = 'DELIVERED',
  HardBounced = 'HARD_BOUNCED',
  Opened = 'OPENED',
  Sending = 'SENDING',
  SpamComplaint = 'SPAM_COMPLAINT',
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  subject: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type EmailTemplateInput = {
  description: Scalars['String']['input']
  subject: Scalars['String']['input']
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
}

export type EmergencyContactInput = {
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
}

export enum EngagementType {
  Completer = 'COMPLETER',
  Dropout = 'DROPOUT',
  NoShow = 'NO_SHOW',
}

export type Enrollment = {
  __typename?: 'Enrollment'
  id: Scalars['ID']['output']
  lastStateTransitionAt: Scalars['DateTime']['output']
  programID: Scalars['String']['output']
  programStartDate?: Maybe<Scalars['Date']['output']>
  state: EnrollmentState
  uid: Scalars['ID']['output']
}

export enum EnrollmentState {
  CancellationPending = 'CANCELLATION_PENDING',
  DidNotStart = 'DID_NOT_START',
  IntakeCallScheduled = 'INTAKE_CALL_SCHEDULED',
  IntakeLoggedIn = 'INTAKE_LOGGED_IN',
  IntakePreRegistered = 'INTAKE_PRE_REGISTERED',
  IntakeRegistered = 'INTAKE_REGISTERED',
  InProgram = 'IN_PROGRAM',
  PostProgram = 'POST_PROGRAM',
  Test = 'TEST',
}

export type FavoriteLesson = {
  __typename?: 'FavoriteLesson'
  favoriteID: Scalars['ID']['output']
  lessonID: Scalars['String']['output']
}

export type FavoriteLessonAdded = {
  __typename?: 'FavoriteLessonAdded'
  favoriteLessons: FavoriteLessons
}

export type FavoriteLessonRemoved = {
  __typename?: 'FavoriteLessonRemoved'
  favoriteLessons: FavoriteLessons
}

export type FavoriteLessons = {
  __typename?: 'FavoriteLessons'
  lessons: Array<FavoriteLesson>
}

export type FeatureSeenResult = {
  __typename?: 'FeatureSeenResult'
  featureID: Scalars['String']['output']
  seenAt: Scalars['DateTime']['output']
}

export type FeedItem = ChatMessage | PatientResponse | ResumedPatientResponse | SmsMessage | VideoCall

export enum FeedOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type FeedResult = FeedSuccess

export type FeedSeenResult = {
  __typename?: 'FeedSeenResult'
  patient: Patient
  seenAt: Scalars['DateTime']['output']
}

export type FeedSuccess = {
  __typename?: 'FeedSuccess'
  items: Array<FeedItem>
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>
}

export type FinancialEligibilityCostEstimate = {
  __typename?: 'FinancialEligibilityCostEstimate'
  intakeCostEstimateInCents: Scalars['Int']['output']
  month1CostEstimateInCents: Scalars['Int']['output']
  month2CostEstimateInCents: Scalars['Int']['output']
  month3CostEstimateInCents: Scalars['Int']['output']
}

export type FinancialEligibilityCostEstimateInput = {
  intakeCostEstimateInCents: Scalars['Int']['input']
  month1CostEstimateInCents: Scalars['Int']['input']
  month2CostEstimateInCents: Scalars['Int']['input']
  month3CostEstimateInCents: Scalars['Int']['input']
}

export type FinancialEligibilityInsuranceSubscriberInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>
  coverageActive: Scalars['Boolean']['input']
  firstname: Scalars['String']['input']
  groupDescription?: InputMaybe<Scalars['String']['input']>
  groupNumber?: InputMaybe<Scalars['String']['input']>
  lastname: Scalars['String']['input']
  memberID: Scalars['String']['input']
}

export type FinancialEligibilityPatientHistory = PatientHistory & {
  __typename?: 'FinancialEligibilityPatientHistory'
  coachID?: Maybe<Scalars['ID']['output']>
  costEstimate?: Maybe<FinancialEligibilityCostEstimate>
  createdAt: Scalars['DateTime']['output']
  date?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  entryID: Scalars['ID']['output']
  source?: Maybe<Scalars['String']['output']>
  type?: Maybe<PatientHistoryType>
}

export type InAppAccountAndSettingsScreen = {
  __typename?: 'InAppAccountAndSettingsScreen'
  cancelPlanScreen?: Maybe<InAppCancelPlanResult>
  paymentsAndPlan?: Maybe<InAppPaymentsAndPlanScreen>
  profileForms?: Maybe<Array<InAppProfileForm>>
  supportSection?: Maybe<InAppSupportSection>
}

export type InAppCancelPlanResult = InAppCancelPlanScreen | InAppPlanCancelledScreen

export type InAppCancelPlanScreen = {
  __typename?: 'InAppCancelPlanScreen'
  cancelPlanButtonCopy: Scalars['String']['output']
  cancelPlanForms: Array<CancelPlanForm>
}

export type InAppChangeTherapist = {
  __typename?: 'InAppChangeTherapist'
  title: Scalars['String']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type InAppCostEstimate = {
  __typename?: 'InAppCostEstimate'
  costEstimate?: Maybe<CostEstimate>
  homeScreen?: Maybe<InAppCostEstimateHomeScreenContent>
  sections: Array<InAppCostEstimateSection>
  settingsScreen?: Maybe<InAppCostEstimateSettingsScreenContent>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppCostEstimateAlertComponent = {
  __typename?: 'InAppCostEstimateAlertComponent'
  copy: Scalars['String']['output']
}

export type InAppCostEstimateComponent =
  | InAppCostEstimateAlertComponent
  | InAppCostEstimateExpandableComponent
  | InAppCostEstimateFeeGroupComponent
  | InAppCostEstimateHorizontalDividerComponent
  | InAppCostEstimateIconTextListComponent
  | InAppCostEstimateMultiTextInRowComponent
  | InAppCostEstimateParagraphComponent
  | InAppCostEstimateTimelineComponent
  | InAppCostEstimateTitleComponent

export type InAppCostEstimateExpandableComponent = {
  __typename?: 'InAppCostEstimateExpandableComponent'
  items: Array<InAppCostEstimateExpandableComponentItem>
}

export type InAppCostEstimateExpandableComponentItem = {
  __typename?: 'InAppCostEstimateExpandableComponentItem'
  copy: Scalars['String']['output']
  expanded?: Maybe<Scalars['Boolean']['output']>
  title: Scalars['String']['output']
}

export type InAppCostEstimateFeeGroupComponent = {
  __typename?: 'InAppCostEstimateFeeGroupComponent'
  items: Array<InAppCostEstimateFeeGroupComponentItem>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppCostEstimateFeeGroupComponentItem = {
  __typename?: 'InAppCostEstimateFeeGroupComponentItem'
  cost?: Maybe<Scalars['String']['output']>
  costCoverage?: Maybe<InAppCostEstimateFeeGroupComponentItemCoverage>
  costDisclaimer?: Maybe<Scalars['String']['output']>
  costSuffix?: Maybe<Scalars['String']['output']>
  fullCost?: Maybe<Scalars['String']['output']>
}

export type InAppCostEstimateFeeGroupComponentItemCoverage = {
  __typename?: 'InAppCostEstimateFeeGroupComponentItemCoverage'
  copy: Scalars['String']['output']
  style?: Maybe<InAppCostEstimateFeeGroupComponentItemCoverageStyle>
}

export enum InAppCostEstimateFeeGroupComponentItemCoverageStyle {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export type InAppCostEstimateHomeScreenContent = {
  __typename?: 'InAppCostEstimateHomeScreenContent'
  linkCopy?: Maybe<Scalars['String']['output']>
}

export type InAppCostEstimateHorizontalDividerComponent = {
  __typename?: 'InAppCostEstimateHorizontalDividerComponent'
  _?: Maybe<Scalars['Boolean']['output']>
}

export enum InAppCostEstimateIcon {
  Calendar = 'CALENDAR',
  Checkmark = 'CHECKMARK',
  DollarSign = 'DOLLAR_SIGN',
  EyeOff = 'EYE_OFF',
  Flag = 'FLAG',
  Heart = 'HEART',
  LessonMindfulness = 'LESSON_MINDFULNESS',
  LessonVideo = 'LESSON_VIDEO',
  NavChat = 'NAV_CHAT',
  Star = 'STAR',
}

export type InAppCostEstimateIconTextListComponent = {
  __typename?: 'InAppCostEstimateIconTextListComponent'
  items: Array<InAppCostEstimateIconTextListComponentItem>
}

export type InAppCostEstimateIconTextListComponentItem = {
  __typename?: 'InAppCostEstimateIconTextListComponentItem'
  copy: Scalars['String']['output']
  /** @deprecated use iconName */
  icon: InAppCostEstimateIcon
  iconName: Scalars['String']['output']
}

export type InAppCostEstimateMultiTextInRowComponent = {
  __typename?: 'InAppCostEstimateMultiTextInRowComponent'
  items: Array<InAppCostEstimateMultiTextInRowComponentItem>
}

export type InAppCostEstimateMultiTextInRowComponentItem = {
  __typename?: 'InAppCostEstimateMultiTextInRowComponentItem'
  copy: Scalars['String']['output']
  style?: Maybe<InAppCostEstimateMultiTextInRowComponentItemStyle>
}

export enum InAppCostEstimateMultiTextInRowComponentItemStyle {
  H1 = 'H1',
  H5 = 'H5',
}

export type InAppCostEstimateParagraphComponent = {
  __typename?: 'InAppCostEstimateParagraphComponent'
  copy: Scalars['String']['output']
}

export type InAppCostEstimateSection = {
  __typename?: 'InAppCostEstimateSection'
  components: Array<InAppCostEstimateComponent>
  style?: Maybe<InAppCostEstimateSectionStyle>
}

export enum InAppCostEstimateSectionStyle {
  Bordered = 'BORDERED',
  Plain = 'PLAIN',
}

export type InAppCostEstimateSettingsScreenContent = {
  __typename?: 'InAppCostEstimateSettingsScreenContent'
  linkCopy?: Maybe<Scalars['String']['output']>
}

export type InAppCostEstimateTimelineComponent = {
  __typename?: 'InAppCostEstimateTimelineComponent'
  items: Array<InAppCostEstimateTimelineComponentItem>
}

export type InAppCostEstimateTimelineComponentItem = {
  __typename?: 'InAppCostEstimateTimelineComponentItem'
  copy: Scalars['String']['output']
  /** @deprecated use iconName */
  icon: InAppCostEstimateIcon
  iconName: Scalars['String']['output']
}

export type InAppCostEstimateTitleComponent = {
  __typename?: 'InAppCostEstimateTitleComponent'
  style?: Maybe<InAppCostEstimateTitleComponentStyle>
  title: Scalars['String']['output']
}

export enum InAppCostEstimateTitleComponentStyle {
  H3 = 'H3',
  H5 = 'H5',
}

export type InAppEnabledTabs = {
  __typename?: 'InAppEnabledTabs'
  chat: Scalars['Boolean']['output']
  community: Scalars['Boolean']['output']
  tabBar: Scalars['Boolean']['output']
  timeline: Scalars['Boolean']['output']
}

export type InAppGetSupport = {
  __typename?: 'InAppGetSupport'
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type InAppGiveFeedback = {
  __typename?: 'InAppGiveFeedback'
  title: Scalars['String']['output']
}

export type InAppPaymentInformation = AllPaymentsCompleted | PaymentSummary | UpcomingPayment

export type InAppPaymentInformationResult = {
  __typename?: 'InAppPaymentInformationResult'
  aperoPaymentInformation?: Maybe<AperoPaymentInformation>
  paymentInformation?: Maybe<InAppPaymentInformation>
  paymentMethod?: Maybe<InAppPaymentMethod>
}

export type InAppPaymentMethod = {
  __typename?: 'InAppPaymentMethod'
  authorizeCard?: Maybe<InAppPaymentPlanScreenPaymentMethodAuthorizeCard>
  authorizeCardSuccess?: Maybe<InAppPaymentPlanScreenPaymentMethodAuthorizeCardSuccess>
  cardInfo?: Maybe<InAppPaymentPlanScreenPaymentMethodCardInfo>
  changeCardOption?: Maybe<InAppPaymentPlanScreenPaymentMethodChangeCardOption>
  copy?: Maybe<Scalars['String']['output']>
  paymentMethodStatus?: Maybe<PaymentMethodStatus>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenDynamicContent = {
  __typename?: 'InAppPaymentPlanScreenDynamicContent'
  paymentMethod?: Maybe<InAppPaymentPlanScreenPaymentMethod>
  paymentStatus?: Maybe<InAppPaymentPlanScreenPaymentStatus>
  paymentSummary?: Maybe<InAppPaymentPlanScreenPaymentSummary>
}

export type InAppPaymentPlanScreenPaymentMethod = {
  __typename?: 'InAppPaymentPlanScreenPaymentMethod'
  authorizeCard?: Maybe<InAppPaymentPlanScreenPaymentMethodAuthorizeCard>
  authorizeCardSuccess?: Maybe<InAppPaymentPlanScreenPaymentMethodAuthorizeCardSuccess>
  cardInfo?: Maybe<InAppPaymentPlanScreenPaymentMethodCardInfo>
  changeCardOption?: Maybe<InAppPaymentPlanScreenPaymentMethodChangeCardOption>
  copy?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenPaymentMethodAuthorizeCard = {
  __typename?: 'InAppPaymentPlanScreenPaymentMethodAuthorizeCard'
  annotation?: Maybe<Scalars['String']['output']>
  buttonCopy?: Maybe<Scalars['String']['output']>
  copy?: Maybe<Scalars['String']['output']>
  errorMessage?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenPaymentMethodAuthorizeCardSuccess = {
  __typename?: 'InAppPaymentPlanScreenPaymentMethodAuthorizeCardSuccess'
  buttonCopy?: Maybe<Scalars['String']['output']>
  copy?: Maybe<Scalars['String']['output']>
  imageUrl?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenPaymentMethodCardInfo = {
  __typename?: 'InAppPaymentPlanScreenPaymentMethodCardInfo'
  expiryDateCopy?: Maybe<Scalars['String']['output']>
  issuer?: Maybe<Scalars['String']['output']>
  lastFourDigitsCopy?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenPaymentMethodChangeCardOption = {
  __typename?: 'InAppPaymentPlanScreenPaymentMethodChangeCardOption'
  confirmActionButtonCopy?: Maybe<Scalars['String']['output']>
  copy?: Maybe<Scalars['String']['output']>
  initialActionButtonCopy?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export enum InAppPaymentPlanScreenPaymentStatus {
  Active = 'ACTIVE',
  Failed = 'FAILED',
}

export type InAppPaymentPlanScreenPaymentSummary =
  | InAppPaymentPlanScreenPaymentSummaryInProgram
  | InAppPaymentPlanScreenPaymentSummaryInProgramNoUpcomingInvoices
  | InAppPaymentPlanScreenPaymentSummaryIntake

export type InAppPaymentPlanScreenPaymentSummaryInProgram = {
  __typename?: 'InAppPaymentPlanScreenPaymentSummaryInProgram'
  amountCopy?: Maybe<Scalars['String']['output']>
  badgeCopy?: Maybe<Scalars['String']['output']>
  dueDateCopy?: Maybe<Scalars['String']['output']>
  weekRangeCopy?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenPaymentSummaryInProgramNoUpcomingInvoices = {
  __typename?: 'InAppPaymentPlanScreenPaymentSummaryInProgramNoUpcomingInvoices'
  badgeCopy?: Maybe<Scalars['String']['output']>
  copy?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenPaymentSummaryIntake = {
  __typename?: 'InAppPaymentPlanScreenPaymentSummaryIntake'
  amountCopy?: Maybe<Scalars['String']['output']>
  badgeCopy?: Maybe<Scalars['String']['output']>
  billingDescription?: Maybe<Scalars['String']['output']>
  conditions?: Maybe<InAppCostEstimateSection>
  intervalCopy?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentPlanScreenProgramSummary = {
  __typename?: 'InAppPaymentPlanScreenProgramSummary'
  buttonCopy?: Maybe<Scalars['String']['output']>
  features?: Maybe<InAppCostEstimateSection>
}

export type InAppPaymentPlanScreenStaticContent = {
  __typename?: 'InAppPaymentPlanScreenStaticContent'
  faq?: Maybe<InAppCostEstimateSection>
  programSummary?: Maybe<InAppPaymentPlanScreenProgramSummary>
  supportContact?: Maybe<InAppPaymentPlanScreenSupportContact>
  title: Scalars['String']['output']
}

export type InAppPaymentPlanScreenSupportContact = {
  __typename?: 'InAppPaymentPlanScreenSupportContact'
  annotation?: Maybe<Scalars['String']['output']>
  buttonCopy?: Maybe<Scalars['String']['output']>
  supportEmail?: Maybe<Scalars['String']['output']>
}

export type InAppPaymentsAndPlanScreen = {
  __typename?: 'InAppPaymentsAndPlanScreen'
  faq?: Maybe<InAppPaymentsFaq>
  programSummary?: Maybe<InAppPaymentsProgramSummary>
  supportContact?: Maybe<InAppPaymentPlanScreenSupportContact>
  title: Scalars['String']['output']
}

export type InAppPaymentsFaq = {
  __typename?: 'InAppPaymentsFAQ'
  components: Array<InAppCostEstimateComponent>
}

export type InAppPaymentsPlanCancelPlanButton = {
  __typename?: 'InAppPaymentsPlanCancelPlanButton'
  buttonCopy: Scalars['String']['output']
}

export type InAppPaymentsProgramSummary = {
  __typename?: 'InAppPaymentsProgramSummary'
  cancelPlanButton?: Maybe<InAppPaymentsPlanCancelPlanButton>
  components: Array<InAppCostEstimateComponent>
}

export type InAppPlanCancelledScreen = {
  __typename?: 'InAppPlanCancelledScreen'
  buttonCopy?: Maybe<Scalars['String']['output']>
  copy?: Maybe<Scalars['String']['output']>
  illustrationURL?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppProfileForm = {
  __typename?: 'InAppProfileForm'
  completionStatusIcon?: Maybe<InAppProfileFormCompletionStatusIcon>
  enableUnseenIcon?: Maybe<Scalars['Boolean']['output']>
  icon?: Maybe<InAppProfileFormIcon>
  id: Scalars['ID']['output']
  sections: Array<InAppProfileFormSection>
  seenStatus?: Maybe<FeatureSeenResult>
  subTitle?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export enum InAppProfileFormCompletionStatusIcon {
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export type InAppProfileFormFieldInput = {
  id: Scalars['String']['input']
  valueDate?: InputMaybe<Scalars['Date']['input']>
  valueString?: InputMaybe<Scalars['String']['input']>
  valueStringArray?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum InAppProfileFormIcon {
  EmergencyContact = 'EMERGENCY_CONTACT',
  Insurance = 'INSURANCE',
  MyProfile = 'MY_PROFILE',
  SelfIdentification = 'SELF_IDENTIFICATION',
  ShippingAddress = 'SHIPPING_ADDRESS',
}

export type InAppProfileFormInputField =
  | InAppProfileFormMultiItemSelector
  | InAppProfileFormSingleItemSelector
  | InAppProfileFormTextInput

export type InAppProfileFormItemSelector = {
  id: Scalars['ID']['output']
  itemsList: InAppProfileFormItemSelectorItemsList
  label?: Maybe<Scalars['String']['output']>
  readOnly: Scalars['Boolean']['output']
  required?: Maybe<Scalars['Boolean']['output']>
}

export type InAppProfileFormItemSelectorItem = {
  __typename?: 'InAppProfileFormItemSelectorItem'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type InAppProfileFormItemSelectorItemsList = {
  __typename?: 'InAppProfileFormItemSelectorItemsList'
  items: Array<InAppProfileFormItemSelectorItem>
  saveButtonLabel: Scalars['String']['output']
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppProfileFormMultiItemSelector = InAppProfileFormItemSelector & {
  __typename?: 'InAppProfileFormMultiItemSelector'
  id: Scalars['ID']['output']
  itemsList: InAppProfileFormItemSelectorItemsList
  label?: Maybe<Scalars['String']['output']>
  readOnly: Scalars['Boolean']['output']
  required?: Maybe<Scalars['Boolean']['output']>
  value?: Maybe<Array<Scalars['String']['output']>>
}

export type InAppProfileFormSection = {
  __typename?: 'InAppProfileFormSection'
  id: Scalars['ID']['output']
  inputFields: Array<InAppProfileFormInputField>
  subTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type InAppProfileFormSingleItemSelector = InAppProfileFormItemSelector & {
  __typename?: 'InAppProfileFormSingleItemSelector'
  id: Scalars['ID']['output']
  itemsList: InAppProfileFormItemSelectorItemsList
  label?: Maybe<Scalars['String']['output']>
  readOnly: Scalars['Boolean']['output']
  required?: Maybe<Scalars['Boolean']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type InAppProfileFormTextInput = {
  __typename?: 'InAppProfileFormTextInput'
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  readOnly: Scalars['Boolean']['output']
  required?: Maybe<Scalars['Boolean']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type InAppProfileFormUpdateResult = {
  __typename?: 'InAppProfileFormUpdateResult'
  updatedField?: Maybe<InAppProfileFormInputField>
}

export type InAppPromptActionPanelActionButton = InAppPromptActionPanelActionButtonDeepLink

export type InAppPromptActionPanelActionButtonDeepLink = {
  __typename?: 'InAppPromptActionPanelActionButtonDeepLink'
  actionName: Scalars['String']['output']
  copy: Scalars['String']['output']
  deepLink: Scalars['String']['output']
}

export enum InAppPromptActionPanelIcon {
  Warning = 'WARNING',
}

export type InAppPromptActionPanelResult = {
  __typename?: 'InAppPromptActionPanelResult'
  annotation?: Maybe<Scalars['String']['output']>
  button?: Maybe<InAppPromptActionPanelActionButton>
  copy?: Maybe<Scalars['String']['output']>
  icon?: Maybe<InAppPromptActionPanelIcon>
  id: Scalars['String']['output']
}

export type InAppRating = {
  __typename?: 'InAppRating'
  followUpQuestionnaire?: Maybe<InAppRatingFollowUpQuestionnaire>
  id: Scalars['ID']['output']
  illustrationUrl?: Maybe<Scalars['String']['output']>
  question: InAppRatingQuestion
  score?: Maybe<Scalars['Int']['output']>
}

export type InAppRatingFollowUpQuestionnaire = {
  __typename?: 'InAppRatingFollowUpQuestionnaire'
  buttonLabel: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type InAppRatingQuestion = {
  __typename?: 'InAppRatingQuestion'
  annotation?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  maxScore: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type InAppSignupFormFound = {
  __typename?: 'InAppSignupFormFound'
  form: SignupForm
}

export type InAppSignupFormInput = {
  accessCode?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Scalars['String']['input']>
}

export type InAppSignupFormNotFound = {
  __typename?: 'InAppSignupFormNotFound'
  accessCode?: Maybe<Scalars['String']['output']>
  source?: Maybe<Scalars['String']['output']>
}

export type InAppSignupFormResult = InAppSignupFormFound | InAppSignupFormNotFound

export type InAppStripeConfig = {
  __typename?: 'InAppStripeConfig'
  publishableKey: Scalars['String']['output']
}

export type InAppSupportSection = {
  __typename?: 'InAppSupportSection'
  changeTherapist?: Maybe<InAppChangeTherapist>
  getSupport?: Maybe<InAppGetSupport>
  giveFeedback?: Maybe<InAppGiveFeedback>
  title: Scalars['String']['output']
}

export type InAppTabIntroduction = {
  __typename?: 'InAppTabIntroduction'
  newTabIntroductionEnabled?: Maybe<Scalars['Boolean']['output']>
  programStepperEnabled?: Maybe<Scalars['Boolean']['output']>
}

export type InAppVideoCallBanner = {
  __typename?: 'InAppVideoCallBanner'
  copy: Scalars['String']['output']
  isPressable?: Maybe<Scalars['Boolean']['output']>
}

export type InAppVideoCallBookCallAction = {
  __typename?: 'InAppVideoCallBookCallAction'
  copy: Scalars['String']['output']
  disabled?: Maybe<Scalars['Boolean']['output']>
  lessonID: Scalars['String']['output']
}

export type InAppVideoCallCancelCallAction = {
  __typename?: 'InAppVideoCallCancelCallAction'
  callID: Scalars['String']['output']
  confirmation?: Maybe<InAppVideoCallCancelCallConfirmation>
  copy: Scalars['String']['output']
  disabled?: Maybe<Scalars['Boolean']['output']>
}

export type InAppVideoCallCancelCallConfirmation = {
  __typename?: 'InAppVideoCallCancelCallConfirmation'
  confirmCopy: Scalars['String']['output']
  copy: Scalars['String']['output']
  declineCopy: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type InAppVideoCallChatHeader = {
  __typename?: 'InAppVideoCallChatHeader'
  copy: Scalars['String']['output']
  joinCallAction?: Maybe<InAppVideoCallJoinCallAction>
  openCallDetailsAction?: Maybe<InAppVideoCallOpenCallDetailsAction>
}

export type InAppVideoCallJoinCallAction = {
  __typename?: 'InAppVideoCallJoinCallAction'
  copy: Scalars['String']['output']
  disabled?: Maybe<Scalars['Boolean']['output']>
  zoomCredentials?: Maybe<InAppVideoCallZoomCredentials>
}

export type InAppVideoCallModal = {
  __typename?: 'InAppVideoCallModal'
  cancelCallAction?: Maybe<InAppVideoCallCancelCallAction>
  content?: Maybe<InAppVideoCallModalContent>
  footer?: Maybe<InAppVideoCallModalFooter>
  joinCallAction?: Maybe<InAppVideoCallJoinCallAction>
  openAutomatically?: Maybe<Scalars['Boolean']['output']>
  rescheduleCallAction?: Maybe<InAppVideoCallRescheduleCallAction>
  title: Scalars['String']['output']
}

export type InAppVideoCallModalContent = {
  __typename?: 'InAppVideoCallModalContent'
  callTime?: Maybe<Scalars['String']['output']>
  copy?: Maybe<Scalars['String']['output']>
  illustrationUrl?: Maybe<Scalars['String']['output']>
}

export type InAppVideoCallModalFooter = {
  __typename?: 'InAppVideoCallModalFooter'
  copy: Scalars['String']['output']
}

export type InAppVideoCallOpenCallDetailsAction = {
  __typename?: 'InAppVideoCallOpenCallDetailsAction'
  copy: Scalars['String']['output']
  disabled?: Maybe<Scalars['Boolean']['output']>
}

export type InAppVideoCallRescheduleCallAction = {
  __typename?: 'InAppVideoCallRescheduleCallAction'
  callID: Scalars['String']['output']
  confirmation?: Maybe<InAppVideoCallRescheduleCallConfirmation>
  copy: Scalars['String']['output']
  disabled?: Maybe<Scalars['Boolean']['output']>
}

export type InAppVideoCallRescheduleCallConfirmation = {
  __typename?: 'InAppVideoCallRescheduleCallConfirmation'
  confirmCopy: Scalars['String']['output']
  copy: Scalars['String']['output']
  declineCopy: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type InAppVideoCallResult = {
  __typename?: 'InAppVideoCallResult'
  banner?: Maybe<InAppVideoCallBanner>
  bookCallAction?: Maybe<InAppVideoCallBookCallAction>
  chatHeader?: Maybe<InAppVideoCallChatHeader>
  modal?: Maybe<InAppVideoCallModal>
  refreshAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<InAppVideoCallStatus>
  videoCall?: Maybe<VideoCall>
}

export enum InAppVideoCallStatus {
  NotScheduled = 'NOT_SCHEDULED',
  Ongoing = 'ONGOING',
  Scheduled = 'SCHEDULED',
  ScheduledToday = 'SCHEDULED_TODAY',
}

export type InAppVideoCallZoomCredentials = {
  __typename?: 'InAppVideoCallZoomCredentials'
  jwtToken: Scalars['String']['output']
  meetingID: Scalars['String']['output']
  meetingPassword?: Maybe<Scalars['String']['output']>
}

export type InProgramVideoCallBookingInformation = {
  __typename?: 'InProgramVideoCallBookingInformation'
  callBookingEnabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  latestCallHad?: Maybe<VideoCall>
  numberOfCallsHad?: Maybe<Scalars['Int']['output']>
  videoCalls: Array<VideoCall>
}

export type InsuranceSubscriberOfDependentInput = {
  dateOfBirth: Scalars['Date']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
}

export type IntakeNoteEmployment = {
  __typename?: 'IntakeNoteEmployment'
  STATUS?: Maybe<IntakeNoteEmploymentStatus>
}

export enum IntakeNoteEmploymentStatus {
  Disabled = 'DISABLED',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED',
  Working = 'WORKING',
}

export type IntakeNoteHealthHistory = {
  __typename?: 'IntakeNoteHealthHistory'
  BIPOLAR: Scalars['Boolean']['output']
  DEPRESSION: Scalars['Boolean']['output']
  DTD: Scalars['Boolean']['output']
  GENERALISED_ANXIETY: Scalars['Boolean']['output']
  PSYCHOSIS: Scalars['Boolean']['output']
  PTSD: Scalars['Boolean']['output']
  SUBSTANCE_ABUSE: Scalars['Boolean']['output']
  SUICIDALITY: Scalars['Boolean']['output']
  THERAPY: Scalars['Boolean']['output']
}

export type IntakeVideoCallBookingInformation = {
  __typename?: 'IntakeVideoCallBookingInformation'
  id: Scalars['ID']['output']
  videoCall?: Maybe<VideoCall>
}

export type Lesson = {
  __typename?: 'Lesson'
  category: LessonCategory
  duration: Scalars['Int']['output']
  id: Scalars['ID']['output']
  programID: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export enum LessonCategory {
  Cbt = 'CBT',
  Default = 'DEFAULT',
  GroupReflection = 'GROUP_REFLECTION',
  HealthQuestionnaire = 'HEALTH_QUESTIONNAIRE',
  MeditationHrv = 'MEDITATION_HRV',
  MeditationMindfulness = 'MEDITATION_MINDFULNESS',
  PersonalReflection = 'PERSONAL_REFLECTION',
  Setup = 'SETUP',
  VideoIntroduction = 'VIDEO_INTRODUCTION',
  VideoRecap = 'VIDEO_RECAP',
  VideoTips = 'VIDEO_TIPS',
}

export type MarkChatMessagesAsSeenResult = UnseenChatMessages

export enum MessageOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Mutation = {
  __typename?: 'Mutation'
  addFavoriteLesson: FavoriteLessonAdded
  addFinancialEligibilityPatientHistory: PatientHistory
  addPatient: Patient
  addPatientHistory: PatientHistory
  addTagToPatient?: Maybe<AddTagToPatientAction>
  addTestUser: Patient
  answerInAppRating: AnswerInAppRatingResult
  cancelPlan: CancelPlanResult
  cancelVideoCallByCoach?: Maybe<Scalars['Void']['output']>
  cancelVideoCallByParticipant: CancelVideoCallByParticipantResult
  changeAccessLevel: Session
  createChatMessage: CreateChatMessageResult
  createCoach: CoachProfile
  /** Create new community post */
  createCommunityPost: CommunityPostCreated
  createPatientTag: PatientTag
  createSmsMessage: CreateSmsMessageResult
  deletePatient?: Maybe<Patient>
  deletePatientHistory?: Maybe<PatientHistory>
  deletePatientTag?: Maybe<Scalars['Void']['output']>
  editChatMessage: EditChatMessageResult
  financialEligibilityLookup: PatientFinancialEligibilityCheck
  hideCommunityPostReply: CommunityPostReplyHidden
  inAppProfileFormUpdate: InAppProfileFormUpdateResult
  login?: Maybe<Session>
  logout?: Maybe<Scalars['Void']['output']>
  markChatMessagesAsSeen: MarkChatMessagesAsSeenResult
  markFeatureSeen: FeatureSeenResult
  markFeedSeen: FeedSeenResult
  refreshAccessToken: Session
  registerParticipant: ParticipantRegistrationResult
  removeFavoriteLesson: FavoriteLessonRemoved
  removeTagFromPatient?: Maybe<Scalars['Void']['output']>
  /** Reply to community post */
  replyToCommunityPost?: Maybe<CommunityPostReplyResult>
  rescheduleInProgramCallByCoach?: Maybe<Scalars['Void']['output']>
  rescheduleIntakeCall?: Maybe<Scalars['Void']['output']>
  rescheduleVideoCallByParticipant: RescheduleVideoCallByParticipantResult
  restartIntake?: Maybe<Scalars['Void']['output']>
  restartPatientInProgram: CreateEnrollmentAction
  savePushNotificationToken: SavePushNotificationTokenResult
  scheduleVideoCallByCoach: ScheduleVideoCallResult
  scheduleVideoCallByParticipant: ScheduleVideoCallByParticipantResult
  sendEmails?: Maybe<Scalars['Void']['output']>
  sendParticipantBackToCallScheduling: CreateEnrollmentAction
  setCallException?: Maybe<VideoCall>
  setCareteam: SetCareteamAction
  setInProgramCallBookingEnabled: InProgramVideoCallBookingInformation
  setPatientAccepted: PatientHistory
  setPatientDeclinedV2: PatientHistory
  setPatientProgramEnd: PatientHistory
  storeMeditationDuration?: Maybe<StoreMeditationDurationResult>
  storePatientResponse?: Maybe<StorePatientResponseResult>
  storePaymentMethod: StorePaymentMethodResult
  submitCallNotes?: Maybe<Scalars['Void']['output']>
  trackAppOpen: TrackAppOpenResult
  trackPushNotificationOpen: TrackPushNotificationOpenResult
  trackPushNotificationPermissionStatus: TrackPushNotificationPermissionStatusResult
  unhideCommunityPostReply: CommunityPostReplyUnhidden
  updateABTestGroups: Patient
  updateCallNotes?: Maybe<VideoCall>
  updatePatient: Patient
  updatePatientQuickNote: Patient
  updateProgramStartDate?: Maybe<Enrollment>
}

export type MutationAddFavoriteLessonArgs = {
  lessonID: Scalars['String']['input']
}

export type MutationAddFinancialEligibilityPatientHistoryArgs = {
  costEstimate?: InputMaybe<FinancialEligibilityCostEstimateInput>
  date: Scalars['Date']['input']
  description: Scalars['String']['input']
  insuranceSubscriber?: InputMaybe<FinancialEligibilityInsuranceSubscriberInput>
  uid: Scalars['ID']['input']
}

export type MutationAddPatientArgs = {
  email: Scalars['EmailAddress']['input']
  firstname: Scalars['String']['input']
  lastname: Scalars['String']['input']
  programID: Scalars['String']['input']
  referralName: Scalars['String']['input']
  region: Scalars['String']['input']
  source?: InputMaybe<Scalars['String']['input']>
}

export type MutationAddPatientHistoryArgs = {
  date: Scalars['String']['input']
  type: PatientHistoryType
  uid: Scalars['ID']['input']
  value: Scalars['JSONObject']['input']
}

export type MutationAddTagToPatientArgs = {
  tagID: Scalars['ID']['input']
  uid: Scalars['ID']['input']
}

export type MutationAddTestUserArgs = {
  email: Scalars['EmailAddress']['input']
  firstname: Scalars['String']['input']
  lastname: Scalars['String']['input']
  primaryCoachID: Scalars['ID']['input']
  programID: Scalars['String']['input']
  programStartDate: Scalars['String']['input']
}

export type MutationAnswerInAppRatingArgs = {
  questionID: Scalars['ID']['input']
  score: Scalars['Int']['input']
}

export type MutationCancelPlanArgs = {
  cancellationReasons: Array<CancellationReasons>
  feedback: Scalars['String']['input']
}

export type MutationCancelVideoCallByCoachArgs = {
  zoomMeetingID: Scalars['String']['input']
}

export type MutationCancelVideoCallByParticipantArgs = {
  callID: Scalars['String']['input']
}

export type MutationChangeAccessLevelArgs = {
  level: AccessLevel
}

export type MutationCreateChatMessageArgs = {
  linksTo?: InputMaybe<ChatMessageLinksToInput>
  text: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationCreateCoachArgs = {
  email: Scalars['String']['input']
}

export type MutationCreateCommunityPostArgs = {
  communityID: Scalars['ID']['input']
  post: CommunityPostInput
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  title: Scalars['String']['input']
}

export type MutationCreatePatientTagArgs = {
  name: Scalars['String']['input']
}

export type MutationCreateSmsMessageArgs = {
  text: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationDeletePatientArgs = {
  uid: Scalars['ID']['input']
}

export type MutationDeletePatientHistoryArgs = {
  entryID: Scalars['ID']['input']
}

export type MutationDeletePatientTagArgs = {
  id: Scalars['ID']['input']
}

export type MutationEditChatMessageArgs = {
  messageIDHash: Scalars['ID']['input']
  text: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationFinancialEligibilityLookupArgs = {
  birthDate?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  groupNumber?: InputMaybe<Scalars['String']['input']>
  lastname: Scalars['String']['input']
  memberID?: InputMaybe<Scalars['String']['input']>
  source: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationHideCommunityPostReplyArgs = {
  replyID: Scalars['ID']['input']
}

export type MutationInAppProfileFormUpdateArgs = {
  field: InAppProfileFormFieldInput
}

export type MutationLoginArgs = {
  idToken: Scalars['String']['input']
}

export type MutationMarkFeatureSeenArgs = {
  featureID: Scalars['String']['input']
}

export type MutationMarkFeedSeenArgs = {
  uid: Scalars['ID']['input']
}

export type MutationRegisterParticipantArgs = {
  accessCode?: InputMaybe<Scalars['String']['input']>
  form: ParticipantRegistrationFormInput
  source?: InputMaybe<Scalars['String']['input']>
}

export type MutationRemoveFavoriteLessonArgs = {
  favoriteID: Scalars['ID']['input']
}

export type MutationRemoveTagFromPatientArgs = {
  tagID: Scalars['ID']['input']
  uid: Scalars['ID']['input']
}

export type MutationReplyToCommunityPostArgs = {
  postID: Scalars['ID']['input']
  reply: CommunityPostReplyInput
}

export type MutationRescheduleInProgramCallByCoachArgs = {
  callID: Scalars['String']['input']
  start: Scalars['DateTime']['input']
}

export type MutationRescheduleIntakeCallArgs = {
  calendarOwnerEmail: Scalars['String']['input']
  start: Scalars['DateTime']['input']
  uid: Scalars['ID']['input']
}

export type MutationRescheduleVideoCallByParticipantArgs = {
  callID: Scalars['String']['input']
}

export type MutationRestartIntakeArgs = {
  uid: Scalars['ID']['input']
}

export type MutationRestartPatientInProgramArgs = {
  description: Scalars['String']['input']
  programStartDate: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationSavePushNotificationTokenArgs = {
  devicePlatform: DevicePlatform
  token: Scalars['String']['input']
}

export type MutationScheduleVideoCallByCoachArgs = {
  callDetails: ScheduleVideoCallInput
}

export type MutationScheduleVideoCallByParticipantArgs = {
  callInput: ScheduleVideoCallByParticipantInput
}

export type MutationSendEmailsArgs = {
  emailTemplateInput: EmailTemplateInput
  uids: Array<Scalars['ID']['input']>
}

export type MutationSendParticipantBackToCallSchedulingArgs = {
  description: Scalars['String']['input']
  programID: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationSetCallExceptionArgs = {
  callException?: InputMaybe<VideoCallException>
  callID: Scalars['String']['input']
}

export type MutationSetCareteamArgs = {
  coachIDs: Array<Scalars['ID']['input']>
  primaryCoachID: Scalars['ID']['input']
  uid: Scalars['ID']['input']
}

export type MutationSetInProgramCallBookingEnabledArgs = {
  description?: InputMaybe<Scalars['String']['input']>
  enabled: Scalars['Boolean']['input']
  uid: Scalars['ID']['input']
}

export type MutationSetPatientAcceptedArgs = {
  programStartDate: Scalars['String']['input']
  shipHRVDeviceToPatient?: InputMaybe<Scalars['Boolean']['input']>
  source: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationSetPatientDeclinedV2Args = {
  description?: InputMaybe<Scalars['String']['input']>
  reasons: Array<PatientDeclinedReasonV2>
  uid: Scalars['ID']['input']
}

export type MutationSetPatientProgramEndArgs = {
  engagement: EngagementType
  programEndMetrics?: InputMaybe<ProgramEndMetricsInput>
  uid: Scalars['ID']['input']
}

export type MutationStoreMeditationDurationArgs = {
  meditationSeconds: Scalars['Float']['input']
  responseID: Scalars['ID']['input']
}

export type MutationStorePatientResponseArgs = {
  category?: InputMaybe<Scalars['String']['input']>
  clientTime: Scalars['String']['input']
  duration?: InputMaybe<Scalars['Int']['input']>
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>
  lessonID: Scalars['String']['input']
  responseID: Scalars['ID']['input']
  sections?: InputMaybe<Array<PatientResponseSectionInput>>
  sentAt: Scalars['DateTime']['input']
  taskID?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type MutationStorePaymentMethodArgs = {
  input: StorePaymentMethodInput
}

export type MutationSubmitCallNotesArgs = {
  callID: Scalars['ID']['input']
  notes: Scalars['String']['input']
}

export type MutationTrackAppOpenArgs = {
  appVersion: Scalars['String']['input']
  deviceID: Scalars['String']['input']
  deviceModel: Scalars['String']['input']
  devicePlatform: DevicePlatform
  osVersion: Scalars['String']['input']
  timezone: Scalars['String']['input']
}

export type MutationTrackPushNotificationOpenArgs = {
  customerIO?: InputMaybe<CustomerIoNotificationOpenInput>
}

export type MutationTrackPushNotificationPermissionStatusArgs = {
  permissionStatus: PushNotificationPermissionStatus
}

export type MutationUnhideCommunityPostReplyArgs = {
  replyID: Scalars['ID']['input']
}

export type MutationUpdateAbTestGroupsArgs = {
  abTests: Array<PatientAbTestInput>
  uid: Scalars['ID']['input']
}

export type MutationUpdateCallNotesArgs = {
  callID: Scalars['ID']['input']
  notes: Scalars['String']['input']
}

export type MutationUpdatePatientArgs = {
  details?: InputMaybe<PatientDetailsInput>
  patient?: InputMaybe<PatientInput>
  privateUserData?: InputMaybe<PrivateUserDataInput>
  uid: Scalars['ID']['input']
}

export type MutationUpdatePatientQuickNoteArgs = {
  quickNote: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type MutationUpdateProgramStartDateArgs = {
  programStartDate: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type OpenScheduleVideoCallLesson = {
  __typename?: 'OpenScheduleVideoCallLesson'
  lessonID?: Maybe<Scalars['String']['output']>
  taskID?: Maybe<Scalars['String']['output']>
}

export type ParticipantProfile = {
  __typename?: 'ParticipantProfile'
  autoOpeningLesson?: Maybe<AutoOpeningLesson>
  enabledTabs: InAppEnabledTabs
  id: Scalars['ID']['output']
  productInfo?: Maybe<ProductInfo>
  program: Program
  registrationComplete: Scalars['Boolean']['output']
  showProfileFabNotification: Scalars['Boolean']['output']
  source?: Maybe<Scalars['String']['output']>
  /** @deprecated supportURL was moved to supportSection in InAppAccountAndSettingsScreen. */
  supportURL?: Maybe<Scalars['String']['output']>
  /** @deprecated Is not used by a new app version. */
  tabIntroduction?: Maybe<InAppTabIntroduction>
}

export type ParticipantRegistered = {
  __typename?: 'ParticipantRegistered'
  email?: Maybe<Scalars['String']['output']>
}

export type ParticipantRegistrationFailed = {
  __typename?: 'ParticipantRegistrationFailed'
  reason: ParticipantRegistrationFailedReason
}

export enum ParticipantRegistrationFailedReason {
  WrongAccessCode = 'WRONG_ACCESS_CODE',
}

export type ParticipantRegistrationFormInput = {
  acceptedConsentIDs: Array<Scalars['String']['input']>
  customQuestions?: InputMaybe<Array<CustomQuestionInput>>
  dateOfBirth: Scalars['Date']['input']
  employer?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  insuranceGroupID?: InputMaybe<Scalars['String']['input']>
  insuranceMemberID?: InputMaybe<Scalars['String']['input']>
  insuranceSubscriberOfDependent?: InputMaybe<InsuranceSubscriberOfDependentInput>
  lastName: Scalars['String']['input']
  phone: Scalars['String']['input']
  referralName?: InputMaybe<Scalars['String']['input']>
  stateOfResidence?: InputMaybe<Scalars['String']['input']>
}

export type ParticipantRegistrationResult = ParticipantRegistered | ParticipantRegistrationFailed

export type Patient = {
  __typename?: 'Patient'
  abTests?: Maybe<Array<PatientAbTest>>
  billingID?: Maybe<Scalars['String']['output']>
  birthDate?: Maybe<Scalars['Date']['output']>
  careTeam?: Maybe<Array<CareTeamMember>>
  costEstimateFailure?: Maybe<PatientCostEstimateFailure>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  credentialingHealthPlan?: Maybe<Scalars['String']['output']>
  details?: Maybe<Scalars['JSONObject']['output']>
  earliestProgramStartDate?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['EmailAddress']['output']>
  emergencyContact?: Maybe<EmergencyContact>
  enrollment: Enrollment
  financialEligibilityCheck?: Maybe<PatientFinancialEligibilityCheck>
  firstname?: Maybe<Scalars['String']['output']>
  genderIdentity?: Maybe<Scalars['String']['output']>
  inProgramVideoCallBookingInformation?: Maybe<InProgramVideoCallBookingInformation>
  indicators?: Maybe<PatientIndicators>
  intakeCallTime?: Maybe<Scalars['DateTime']['output']>
  lastStateTransitionAt?: Maybe<Scalars['DateTime']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  preferredName?: Maybe<Scalars['String']['output']>
  privateUserData: PrivateUserData
  quickNote?: Maybe<Scalars['String']['output']>
  racialIdentities?: Maybe<Array<Scalars['String']['output']>>
  shippingAddress?: Maybe<Address>
  state?: Maybe<PatientState>
  stateOfResidence?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<PatientTag>>
  uid: Scalars['ID']['output']
  videoCallBookingInformation?: Maybe<VideoCallBookingInformation>
}

export type PatientAbTest = {
  __typename?: 'PatientABTest'
  assignedGroup?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  possibleGroups: Array<Scalars['String']['output']>
}

export type PatientAbTestInput = {
  assignedGroup?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type PatientCompletedResponsesIndicator = {
  __typename?: 'PatientCompletedResponsesIndicator'
  count: Scalars['Int']['output']
  date: Scalars['Date']['output']
}

export type PatientCostEstimateFailure = {
  __typename?: 'PatientCostEstimateFailure'
  createdAt: Scalars['DateTime']['output']
  reason: Scalars['String']['output']
}

export enum PatientDeclinedReasonV2 {
  ClinicalHighAcuity = 'CLINICAL_HIGH_ACUITY',
  ClinicalLowAcuity = 'CLINICAL_LOW_ACUITY',
  ClinicalOther = 'CLINICAL_OTHER',
  OperationalDuplicateAccount = 'OPERATIONAL_DUPLICATE_ACCOUNT',
  OperationalInsuranceCostShareTooHigh = 'OPERATIONAL_INSURANCE_COST_SHARE_TOO_HIGH',
  OperationalInsuranceMedicaidOrMedicare = 'OPERATIONAL_INSURANCE_MEDICAID_OR_MEDICARE',
  OperationalInsuranceOutOfNetwork = 'OPERATIONAL_INSURANCE_OUT_OF_NETWORK',
  OperationalNoProvidersWithRequiredCredentialing = 'OPERATIONAL_NO_PROVIDERS_WITH_REQUIRED_CREDENTIALING',
  OperationalOutsideGeographicCoverage = 'OPERATIONAL_OUTSIDE_GEOGRAPHIC_COVERAGE',
  OperationalSwitchedOrLostInsurance = 'OPERATIONAL_SWITCHED_OR_LOST_INSURANCE',
  OperationalTechnicalIssue = 'OPERATIONAL_TECHNICAL_ISSUE',
  OperationalUnder_18 = 'OPERATIONAL_UNDER_18',
  PersonalFeelsBetter = 'PERSONAL_FEELS_BETTER',
  PersonalFoundAlternativeCare = 'PERSONAL_FOUND_ALTERNATIVE_CARE',
  PersonalFrequentTravel = 'PERSONAL_FREQUENT_TRAVEL',
  PersonalLifeEvent = 'PERSONAL_LIFE_EVENT',
  PersonalNotInterested = 'PERSONAL_NOT_INTERESTED',
  PersonalNotResponding = 'PERSONAL_NOT_RESPONDING',
  PersonalOther = 'PERSONAL_OTHER',
  PersonalPrefersOtherLanguage = 'PERSONAL_PREFERS_OTHER_LANGUAGE',
  PersonalPrefersTraditionalOneToOneTherapy = 'PERSONAL_PREFERS_TRADITIONAL_ONE_TO_ONE_THERAPY',
  PersonalTimeCommitment = 'PERSONAL_TIME_COMMITMENT',
}

export type PatientDetailsInput = {
  employer?: InputMaybe<Scalars['String']['input']>
  excludedFromCalculations?: InputMaybe<Scalars['Boolean']['input']>
  externalReferenceID?: InputMaybe<Scalars['String']['input']>
  insuranceGroupID?: InputMaybe<Scalars['String']['input']>
  insuranceMemberID?: InputMaybe<Scalars['String']['input']>
  isDependent?: InputMaybe<Scalars['Boolean']['input']>
  referralName?: InputMaybe<Scalars['String']['input']>
  referralType?: InputMaybe<Scalars['String']['input']>
  registrationSource?: InputMaybe<Scalars['String']['input']>
  sex?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Scalars['String']['input']>
}

export type PatientEngagementIndicator = {
  __typename?: 'PatientEngagementIndicator'
  completedIntros: Scalars['Int']['output']
  totalPossibleIntros: Scalars['Int']['output']
  type: EngagementType
}

export type PatientFinancialEligibilityCheck = {
  __typename?: 'PatientFinancialEligibilityCheck'
  aperoEligibilityID: Scalars['String']['output']
  coverageActive: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  subscriber: PatientFinancialEligibilityCheckSubscriber
  warnings?: Maybe<Array<Scalars['String']['output']>>
}

export type PatientFinancialEligibilityCheckSubscriber = {
  __typename?: 'PatientFinancialEligibilityCheckSubscriber'
  birthDate?: Maybe<Scalars['Date']['output']>
  firstName: Scalars['String']['output']
  groupDescription?: Maybe<Scalars['String']['output']>
  groupNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type PatientHistory = {
  coachID?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  date?: Maybe<Scalars['String']['output']>
  entryID: Scalars['ID']['output']
  source?: Maybe<Scalars['String']['output']>
  type?: Maybe<PatientHistoryType>
}

export enum PatientHistoryOrderByInput {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
}

export enum PatientHistoryType {
  Accepted = 'ACCEPTED',
  AnonymousNote = 'ANONYMOUS_NOTE',
  Brs = 'BRS',
  Burnout = 'BURNOUT',
  Custom = 'CUSTOM',
  Declined = 'DECLINED',
  FinancialEligibilityConfirmed = 'FINANCIAL_ELIGIBILITY_CONFIRMED',
  Gad7 = 'GAD7',
  IntakeNote = 'INTAKE_NOTE',
  IntakeNoteCoaching = 'INTAKE_NOTE_COACHING',
  MissedIntakeCall = 'MISSED_INTAKE_CALL',
  Note = 'NOTE',
  ParticipantReEnrolled = 'PARTICIPANT_RE_ENROLLED',
  PhoneCall = 'PHONE_CALL',
  Phq9 = 'PHQ9',
  ProgramEnd = 'PROGRAM_END',
  Pss4 = 'PSS4',
  Sms = 'SMS',
  TreatmentPlan = 'TREATMENT_PLAN',
  VideoCall = 'VIDEO_CALL',
  Who5 = 'WHO5',
  Wpai = 'WPAI',
}

export type PatientIndicators = {
  __typename?: 'PatientIndicators'
  completedResponses?: Maybe<Array<PatientCompletedResponsesIndicator>>
  engagement?: Maybe<PatientEngagementIndicator>
  financialEligibilityConfirmed?: Maybe<Scalars['Boolean']['output']>
  newMessages?: Maybe<PatientNewMessageIndicator>
  suicidal?: Maybe<Scalars['Boolean']['output']>
}

export type PatientIndicatorsCompletedResponsesArgs = {
  endDate: Scalars['Date']['input']
  startDate: Scalars['Date']['input']
}

export type PatientInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  firstname?: InputMaybe<Scalars['String']['input']>
  genderIdentity?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  preferredName?: InputMaybe<Scalars['String']['input']>
  racialIdentities?: InputMaybe<Array<Scalars['String']['input']>>
  shippingAddress?: InputMaybe<AddressInput>
  stateOfResidence?: InputMaybe<Scalars['String']['input']>
}

export type PatientNewMessageIndicator = {
  __typename?: 'PatientNewMessageIndicator'
  count: Scalars['Int']['output']
}

export enum PatientProgramWeek {
  Week_0 = 'WEEK_0',
  Week_1 = 'WEEK_1',
  Week_2 = 'WEEK_2',
  Week_3 = 'WEEK_3',
  Week_4 = 'WEEK_4',
  Week_5 = 'WEEK_5',
  Week_6 = 'WEEK_6',
  Week_7 = 'WEEK_7',
  Week_8 = 'WEEK_8',
  Week_9 = 'WEEK_9',
  Week_10 = 'WEEK_10',
  Week_11 = 'WEEK_11',
  Week_12 = 'WEEK_12',
  Week_12Plus = 'WEEK_12_PLUS',
}

export type PatientResponse = {
  __typename?: 'PatientResponse'
  category?: Maybe<Scalars['String']['output']>
  clientTime: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  duration?: Maybe<Scalars['Int']['output']>
  groupID?: Maybe<Scalars['String']['output']>
  isCompleted: Scalars['Boolean']['output']
  lessonID: Scalars['String']['output']
  meditationSeconds?: Maybe<Scalars['Float']['output']>
  programID: Scalars['String']['output']
  responseID: Scalars['ID']['output']
  sections: Array<PatientResponseSection>
  sentAt: Scalars['DateTime']['output']
  taskID?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  uid: Scalars['ID']['output']
  weekNbr?: Maybe<Scalars['Int']['output']>
}

export type PatientResponseSectionsArgs = {
  groupSections?: InputMaybe<Scalars['Boolean']['input']>
}

export type PatientResponseSection = {
  __typename?: 'PatientResponseSection'
  description?: Maybe<Scalars['String']['output']>
  entries: Array<PatientResponseSectionEntry>
  isSharedWithGroup: Scalars['Boolean']['output']
  position: Scalars['Int']['output']
  responseID: Scalars['ID']['output']
  sectionID: Scalars['ID']['output']
  title?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  useContextFrom?: Maybe<Scalars['String']['output']>
}

export type PatientResponseSectionEntry = {
  __typename?: 'PatientResponseSectionEntry'
  entryID: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  position: Scalars['Int']['output']
  question?: Maybe<Scalars['String']['output']>
  rawValue?: Maybe<Scalars['JSON']['output']>
  tag?: Maybe<Scalars['String']['output']>
}

export type PatientResponseSectionEntryInput = {
  entryID: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  position: Scalars['Int']['input']
  profileVariableKey?: InputMaybe<Scalars['String']['input']>
  question?: InputMaybe<Scalars['String']['input']>
  rawValue?: InputMaybe<Scalars['JSON']['input']>
  tag?: InputMaybe<Scalars['String']['input']>
}

export type PatientResponseSectionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  entries?: InputMaybe<Array<PatientResponseSectionEntryInput>>
  position: Scalars['Int']['input']
  sectionID: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  useContextFrom?: InputMaybe<Scalars['String']['input']>
}

export enum PatientSortField {
  CallScheduledAt = 'CALL_SCHEDULED_AT',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  LastStateTransitionAt = 'LAST_STATE_TRANSITION_AT',
}

export enum PatientSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum PatientState {
  DidNotStart = 'DID_NOT_START',
  /** @deprecated Use INTAKE_REGISTERED, INTAKE_LOGGED_IN, INTAKE_CALL_SCHEDULED instead */
  Intake = 'INTAKE',
  IntakeCallScheduled = 'INTAKE_CALL_SCHEDULED',
  IntakeLoggedIn = 'INTAKE_LOGGED_IN',
  IntakePreRegistered = 'INTAKE_PRE_REGISTERED',
  IntakeRegistered = 'INTAKE_REGISTERED',
  PostProgram = 'POST_PROGRAM',
  Test = 'TEST',
  Treatment = 'TREATMENT',
}

export type PatientTag = {
  __typename?: 'PatientTag'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export enum PaymentMethodStatus {
  Active = 'ACTIVE',
  Failed = 'FAILED',
}

export type PaymentSummary = {
  __typename?: 'PaymentSummary'
  amountCopy?: Maybe<Scalars['String']['output']>
  badgeCopy?: Maybe<Scalars['String']['output']>
  billingDescription?: Maybe<Scalars['String']['output']>
  conditions?: Maybe<Array<InAppCostEstimateComponent>>
  intervalCopy?: Maybe<Scalars['String']['output']>
}

export type PrefilledIntakeNote = {
  __typename?: 'PrefilledIntakeNote'
  EMPLOYMENT?: Maybe<IntakeNoteEmployment>
  HEALTH_HISTORY?: Maybe<IntakeNoteHealthHistory>
  OBSTACLES?: Maybe<Scalars['String']['output']>
}

export type PrivateUserData = {
  __typename?: 'PrivateUserData'
  firstTimeLoginAt?: Maybe<Scalars['DateTime']['output']>
  groupID?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isFreeRider: Scalars['Boolean']['output']
  programID?: Maybe<Scalars['ID']['output']>
  programStartDate?: Maybe<Scalars['DateTime']['output']>
  timezone?: Maybe<Scalars['String']['output']>
  variables?: Maybe<PrivateUserDataVariables>
}

export type PrivateUserDataInput = {
  isFreeRider?: InputMaybe<Scalars['Boolean']['input']>
}

export type PrivateUserDataVariables = {
  __typename?: 'PrivateUserDataVariables'
  hrvDevice?: Maybe<Scalars['String']['output']>
}

export type ProductInfo = {
  __typename?: 'ProductInfo'
  billingCopy?: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'weeklyPriceDiscountLabel' field instead */
  discountLabelCopy?: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'weeklyPriceDiscountCopy' field instead */
  discountPriceCopy?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** @deprecated Use 'weeklyPriceCopy' field instead. */
  priceCopy: Scalars['String']['output']
  /** @deprecated Not in use anymore */
  promotionalPriceCopy?: Maybe<Scalars['String']['output']>
  /** @deprecated Not in use anymore, your application logic should handle this */
  promotionalPriceIntervalCopy?: Maybe<Scalars['String']['output']>
  weeklyPriceCents: Scalars['Int']['output']
  weeklyPriceCopy: Scalars['String']['output']
  weeklyPriceDiscountCopy?: Maybe<Scalars['String']['output']>
  weeklyPriceDiscountLabel?: Maybe<Scalars['String']['output']>
  weeklyPriceDiscoutCents?: Maybe<Scalars['Int']['output']>
}

export type Program = {
  __typename?: 'Program'
  currentTheme: ProgramTheme
  id: Scalars['ID']['output']
  lastWeekNbr: Scalars['Int']['output']
}

export type ProgramEndMetricInput = {
  baseline: Scalars['Int']['input']
  programEnd?: InputMaybe<Scalars['Int']['input']>
}

export type ProgramEndMetricsInput = {
  GAD7?: InputMaybe<ProgramEndMetricInput>
  PHQ9?: InputMaybe<ProgramEndMetricInput>
  WHO5?: InputMaybe<ProgramEndMetricInput>
}

export type ProgramTheme = {
  __typename?: 'ProgramTheme'
  id: Scalars['ID']['output']
  programID: Scalars['ID']['output']
}

export enum PushNotificationPermissionStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  NotDetermined = 'NOT_DETERMINED',
  NotWanted = 'NOT_WANTED',
  Unknown = 'UNKNOWN',
}

export type Query = {
  __typename?: 'Query'
  accountsForSignup?: Maybe<SignupAccounts>
  afterAppLoadScreen?: Maybe<AfterAppLoadScreen>
  appStoreReviewPrompt: AppStoreReviewPromptStatus
  availableInAppRating?: Maybe<InAppRating>
  availableVideoCallSlots: AvailableVideoCallSlotsResult
  billingContacts: Array<BillingContact>
  chatMessages: ChatMessagesResult
  checkAppVersion: CheckAppVersionResult
  coachAvailability: CoachAvailabilityResult
  /** Single community post */
  communityPost: CommunityPost
  /** Feed of community posts that have been published */
  communityPosts: CommunityPostsResult
  costEstimateLookup: CostEstimateLookup
  emailTemplates: Array<EmailTemplate>
  emails: Array<Email>
  favoriteLessons: FavoriteLessons
  feed: FeedResult
  inAppAccountAndSettingsScreen?: Maybe<InAppAccountAndSettingsScreen>
  inAppCostEstimate?: Maybe<InAppCostEstimate>
  inAppPaymentInformation?: Maybe<InAppPaymentInformationResult>
  inAppPaymentPlanScreenDynamicContent?: Maybe<InAppPaymentPlanScreenDynamicContent>
  inAppPaymentPlanScreenStaticContent?: Maybe<InAppPaymentPlanScreenStaticContent>
  inAppPromptActionPanel?: Maybe<InAppPromptActionPanelResult>
  inAppSignupForm: InAppSignupFormResult
  inAppStripeConfig: InAppStripeConfig
  inAppVideoCall?: Maybe<InAppVideoCallResult>
  /** Temporary endpoint for fetching the most recently published post */
  lastPublishedCommunityPost?: Maybe<CommunityPost>
  participantProfile: ParticipantProfile
  patient: Patient
  patientHistory: Array<PatientHistory>
  patientTags: Array<PatientTag>
  patients: Array<Patient>
  postProgramLessons: Array<WeekTheme>
  prefilledIntakeNote: PrefilledIntakeNote
  relevantHistory: RelevantHistoryResult
  response?: Maybe<PatientResponse>
  searchPatientsV2: SearchPatientsResponse
  session?: Maybe<Session>
  sources: Array<Source>
  taskRecommendations: TaskRecommendationsResult
  taskRecommendationsForPatient: TaskRecommendationsResult
  /** Feed of unpublished community posts */
  unpublishedCommunityPosts: CommunityPostsResult
  unseenChatMessages: UnseenChatMessages
  videoCallUrl: Scalars['String']['output']
  whatIsIncludedSection?: Maybe<InAppCostEstimateSection>
}

export type QueryBillingContactsArgs = {
  billingIDs: Array<Scalars['String']['input']>
}

export type QueryChatMessagesArgs = {
  after?: InputMaybe<Scalars['DateTime']['input']>
  before?: InputMaybe<Scalars['DateTime']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<MessageOrder>
  uid: Scalars['ID']['input']
}

export type QueryCheckAppVersionArgs = {
  clientOS: ClientOs
  clientVersion: Scalars['String']['input']
}

export type QueryCommunityPostArgs = {
  postID: Scalars['ID']['input']
}

export type QueryCostEstimateLookupArgs = {
  uid: Scalars['ID']['input']
}

export type QueryEmailsArgs = {
  uid: Scalars['ID']['input']
}

export type QueryFeedArgs = {
  after?: InputMaybe<Scalars['DateTime']['input']>
  before?: InputMaybe<Scalars['DateTime']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<FeedOrder>
  uid: Scalars['ID']['input']
}

export type QueryInAppSignupFormArgs = {
  input: InAppSignupFormInput
}

export type QueryInAppVideoCallArgs = {
  timezone: Scalars['String']['input']
}

export type QueryPatientArgs = {
  uid: Scalars['ID']['input']
}

export type QueryPatientHistoryArgs = {
  orderBy?: InputMaybe<PatientHistoryOrderByInput>
  type?: InputMaybe<PatientHistoryType>
  uid: Scalars['ID']['input']
}

export type QueryPatientsArgs = {
  uids: Array<Scalars['ID']['input']>
}

export type QueryPrefilledIntakeNoteArgs = {
  uid: Scalars['ID']['input']
}

export type QueryRelevantHistoryArgs = {
  ignoreResponseIDs?: InputMaybe<Array<Scalars['String']['input']>>
  lessonIDs: Array<Scalars['String']['input']>
}

export type QueryResponseArgs = {
  responseID: Scalars['ID']['input']
}

export type QuerySearchPatientsV2Args = {
  coachIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  eligibilityUnconfirmed?: InputMaybe<Scalars['Boolean']['input']>
  includeOnlyPrimaryPatients?: InputMaybe<Scalars['Boolean']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<PatientSortField>
  sortOrder?: InputMaybe<PatientSortOrder>
  sources?: InputMaybe<Array<Scalars['String']['input']>>
  states?: InputMaybe<Array<EnrollmentState>>
  statesOfResidence?: InputMaybe<Array<Scalars['String']['input']>>
  tagIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  weeks?: InputMaybe<Array<PatientProgramWeek>>
}

export type QueryTaskRecommendationsArgs = {
  timezone: Scalars['String']['input']
}

export type QueryTaskRecommendationsForPatientArgs = {
  timezone: Scalars['String']['input']
  uid: Scalars['ID']['input']
}

export type QueryVideoCallUrlArgs = {
  zoomMeetingID: Scalars['String']['input']
}

export enum ReEnrollmentReason {
  RescheduleCall = 'RESCHEDULE_CALL',
  ResetParticipant = 'RESET_PARTICIPANT',
  RestartParticipant = 'RESTART_PARTICIPANT',
}

export type RelevantHistoryResult = {
  __typename?: 'RelevantHistoryResult'
  responses: Array<PatientResponse>
}

export type RescheduleVideoCallByParticipantResult = OpenScheduleVideoCallLesson

export type ResumedPatientResponse = {
  __typename?: 'ResumedPatientResponse'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  resumedResponse: PatientResponse
}

export type SavePushNotificationTokenResult = {
  __typename?: 'SavePushNotificationTokenResult'
  success: Scalars['Boolean']['output']
}

export type ScheduleVideoCallByParticipantInput = {
  availabilityCalendarEventID: Scalars['String']['input']
  coachEmail: Scalars['String']['input']
  startTime: Scalars['DateTime']['input']
  timezone: Scalars['String']['input']
}

export type ScheduleVideoCallByParticipantResult = VideoCall | VideoCallSlotTaken

export type ScheduleVideoCallInput = {
  durationMinutes: Scalars['Int']['input']
  reason: ScheduledVideoCallReason
  startTime: Scalars['DateTime']['input']
  uid: Scalars['ID']['input']
}

export type ScheduleVideoCallResult = {
  __typename?: 'ScheduleVideoCallResult'
  callID: Scalars['ID']['output']
  coachID: Scalars['ID']['output']
  reason: ScheduledVideoCallReason
  scheduledEndTime: Scalars['DateTime']['output']
  scheduledStartTime: Scalars['DateTime']['output']
  uid: Scalars['ID']['output']
  zoomMeetingID: Scalars['String']['output']
}

export enum ScheduledVideoCallReason {
  EngagementIssue = 'ENGAGEMENT_ISSUE',
  EscalatingSymptoms = 'ESCALATING_SYMPTOMS',
  LifeIssue = 'LIFE_ISSUE',
  OtherReason = 'OTHER_REASON',
  PatientInitiated = 'PATIENT_INITIATED',
}

export type SearchPatientsResponse = {
  __typename?: 'SearchPatientsResponse'
  patientCount: Scalars['Int']['output']
  patients: Array<Patient>
}

export type Session = {
  __typename?: 'Session'
  email?: Maybe<Scalars['String']['output']>
  firebaseToken?: Maybe<Scalars['String']['output']>
  isAdmin?: Maybe<Scalars['Boolean']['output']>
  isCareCoordinator?: Maybe<Scalars['Boolean']['output']>
  isCoach?: Maybe<Scalars['Boolean']['output']>
  isCommunityFacilitator?: Maybe<Scalars['Boolean']['output']>
  isManager?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  refreshAt?: Maybe<Scalars['BigInt']['output']>
  uid: Scalars['ID']['output']
  validUntil?: Maybe<Scalars['BigInt']['output']>
}

export type SetCareteamAction = {
  __typename?: 'SetCareteamAction'
  coachIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  primaryCoachID?: Maybe<Scalars['ID']['output']>
  uid?: Maybe<Scalars['ID']['output']>
}

export type SignupAccountDetails = {
  __typename?: 'SignupAccountDetails'
  healthPlan?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
  source?: Maybe<Scalars['String']['output']>
  subAccounts?: Maybe<Array<SignupAccountDetails>>
}

export type SignupAccounts = {
  __typename?: 'SignupAccounts'
  healthPlans: Array<SignupAccountDetails>
  organizations: Array<SignupAccountDetails>
}

export type SignupForm = {
  __typename?: 'SignupForm'
  screens: Array<SignupFormScreen>
}

export type SignupFormConsentDocument = {
  __typename?: 'SignupFormConsentDocument'
  id: Scalars['String']['output']
  path: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type SignupFormConsentItem = {
  __typename?: 'SignupFormConsentItem'
  documents: Array<SignupFormConsentDocument>
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
}

export type SignupFormConsents = {
  __typename?: 'SignupFormConsents'
  fieldName: SignupFormFieldName
  items: Array<SignupFormConsentItem>
}

export type SignupFormCustomQuestion = {
  __typename?: 'SignupFormCustomQuestion'
  question: Scalars['String']['output']
  questionID: Scalars['String']['output']
}

export type SignupFormDateSelector = {
  __typename?: 'SignupFormDateSelector'
  annotation?: Maybe<Scalars['String']['output']>
  fieldName: SignupFormFieldName
  label?: Maybe<Scalars['String']['output']>
  placeholder?: Maybe<Scalars['String']['output']>
  validation?: Maybe<SignupFormDateSelectorValidation>
}

export type SignupFormDateSelectorValidation = {
  __typename?: 'SignupFormDateSelectorValidation'
  minimumYears?: Maybe<Scalars['Int']['output']>
  required?: Maybe<Scalars['Boolean']['output']>
}

export type SignupFormEmailInput = {
  __typename?: 'SignupFormEmailInput'
  annotation?: Maybe<Scalars['String']['output']>
  fieldName: SignupFormFieldName
  label?: Maybe<Scalars['String']['output']>
  placeholder?: Maybe<Scalars['String']['output']>
  validation?: Maybe<SignupFormEmailInputValidation>
}

export type SignupFormEmailInputValidation = {
  __typename?: 'SignupFormEmailInputValidation'
  required?: Maybe<Scalars['Boolean']['output']>
}

export enum SignupFormFieldName {
  AcceptedConsents = 'ACCEPTED_CONSENTS',
  CustomQuestion = 'CUSTOM_QUESTION',
  DateOfBirth = 'DATE_OF_BIRTH',
  Email = 'EMAIL',
  Employer = 'EMPLOYER',
  FirstName = 'FIRST_NAME',
  InsuranceGroupId = 'INSURANCE_GROUP_ID',
  InsuranceMemberId = 'INSURANCE_MEMBER_ID',
  InsuranceSubscriberOfDependentDateOfBirth = 'INSURANCE_SUBSCRIBER_OF_DEPENDENT_DATE_OF_BIRTH',
  InsuranceSubscriberOfDependentFirstName = 'INSURANCE_SUBSCRIBER_OF_DEPENDENT_FIRST_NAME',
  InsuranceSubscriberOfDependentLastName = 'INSURANCE_SUBSCRIBER_OF_DEPENDENT_LAST_NAME',
  LastName = 'LAST_NAME',
  Phone = 'PHONE',
  ReferralName = 'REFERRAL_NAME',
  StateOfResidence = 'STATE_OF_RESIDENCE',
}

export type SignupFormInput =
  | SignupFormConsents
  | SignupFormDateSelector
  | SignupFormEmailInput
  | SignupFormItemSelector
  | SignupFormPhoneNumberInput
  | SignupFormTextInput

export type SignupFormIsDependentScreen = {
  __typename?: 'SignupFormIsDependentScreen'
  id: Scalars['ID']['output']
  nextScreenForDependent: Scalars['String']['output']
  nextScreenForNonDependent: Scalars['String']['output']
  selector: SignupFormIsDependentSelector
  title?: Maybe<Scalars['String']['output']>
  titleAnnotation?: Maybe<Scalars['String']['output']>
}

export type SignupFormIsDependentSelector = {
  __typename?: 'SignupFormIsDependentSelector'
  items: Array<SignupFormIsDependentSelectorItem>
}

export type SignupFormIsDependentSelectorItem = {
  __typename?: 'SignupFormIsDependentSelectorItem'
  label: Scalars['String']['output']
  value: Scalars['Boolean']['output']
}

export type SignupFormItemSelector = {
  __typename?: 'SignupFormItemSelector'
  annotation?: Maybe<Scalars['String']['output']>
  customQuestion?: Maybe<SignupFormCustomQuestion>
  fieldName: SignupFormFieldName
  items: Array<SignupFormItemSelectorItem>
  label?: Maybe<Scalars['String']['output']>
  placeholder?: Maybe<Scalars['String']['output']>
  validation?: Maybe<SignupFormItemSelectorValidation>
}

export type SignupFormItemSelectorItem = {
  __typename?: 'SignupFormItemSelectorItem'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SignupFormItemSelectorValidation = {
  __typename?: 'SignupFormItemSelectorValidation'
  required?: Maybe<Scalars['Boolean']['output']>
}

export enum SignupFormPhoneNumberFormat {
  Us = 'US',
}

export type SignupFormPhoneNumberInput = {
  __typename?: 'SignupFormPhoneNumberInput'
  annotation?: Maybe<Scalars['String']['output']>
  fieldName: SignupFormFieldName
  label?: Maybe<Scalars['String']['output']>
  placeholder?: Maybe<Scalars['String']['output']>
  validation?: Maybe<SignupFormPhoneNumberInputValidation>
}

export type SignupFormPhoneNumberInputValidation = {
  __typename?: 'SignupFormPhoneNumberInputValidation'
  format?: Maybe<SignupFormPhoneNumberFormat>
  required?: Maybe<Scalars['Boolean']['output']>
}

export type SignupFormRegularScreen = {
  __typename?: 'SignupFormRegularScreen'
  disclaimer?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inputs: Array<SignupFormInput>
  title?: Maybe<Scalars['String']['output']>
  titleAnnotation?: Maybe<Scalars['String']['output']>
}

export type SignupFormScreen = SignupFormIsDependentScreen | SignupFormRegularScreen

export type SignupFormTextInput = {
  __typename?: 'SignupFormTextInput'
  annotation?: Maybe<Scalars['String']['output']>
  customQuestion?: Maybe<SignupFormCustomQuestion>
  fieldName: SignupFormFieldName
  label?: Maybe<Scalars['String']['output']>
  placeholder?: Maybe<Scalars['String']['output']>
  validation?: Maybe<SignupFormTextInputValidation>
}

export type SignupFormTextInputValidation = {
  __typename?: 'SignupFormTextInputValidation'
  required?: Maybe<Scalars['Boolean']['output']>
}

export type SmsMessage = {
  __typename?: 'SmsMessage'
  coachID?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  direction: SmsMessageDirection
  status: SmsMessageStatus
  text: Scalars['String']['output']
  twilioMessageID: Scalars['ID']['output']
  uid: Scalars['ID']['output']
}

export enum SmsMessageDirection {
  ToCoach = 'TO_COACH',
  ToPatient = 'TO_PATIENT',
}

export enum SmsMessageStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Queued = 'QUEUED',
  Received = 'RECEIVED',
  Scheduled = 'SCHEDULED',
  Sending = 'SENDING',
  Sent = 'SENT',
  Undelivered = 'UNDELIVERED',
}

export type Source = {
  __typename?: 'Source'
  name: Scalars['String']['output']
}

export type StoreMeditationDurationResult = {
  __typename?: 'StoreMeditationDurationResult'
  response?: Maybe<PatientResponse>
}

export type StorePatientResponseResult = {
  __typename?: 'StorePatientResponseResult'
  response?: Maybe<PatientResponse>
}

export type StorePaymentMethodCloseAction = {
  __typename?: 'StorePaymentMethodCloseAction'
  copy: Scalars['String']['output']
}

export type StorePaymentMethodInput = {
  stripeCard?: InputMaybe<StripeCardInput>
}

export type StorePaymentMethodResult = StorePaymentMethodResultSuccess

export type StorePaymentMethodResultSuccess = {
  __typename?: 'StorePaymentMethodResultSuccess'
  closeAction?: Maybe<StorePaymentMethodCloseAction>
  copy?: Maybe<Scalars['String']['output']>
  photoUrl?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type StripeCardInput = {
  cardToken: Scalars['String']['input']
}

export type TaskRecommendation = {
  __typename?: 'TaskRecommendation'
  banner?: Maybe<TaskRecommendationBanner>
  completedAt?: Maybe<Scalars['DateTime']['output']>
  isDisabled: Scalars['Boolean']['output']
  lessonID: Scalars['ID']['output']
  response?: Maybe<PatientResponse>
  taskID: Scalars['ID']['output']
}

export type TaskRecommendationBanner = {
  __typename?: 'TaskRecommendationBanner'
  label: Scalars['String']['output']
}

export type TaskRecommendationsResult = {
  __typename?: 'TaskRecommendationsResult'
  taskRecommendations: Array<TaskRecommendation>
}

export type TrackAppOpenResult = {
  __typename?: 'TrackAppOpenResult'
  success: Scalars['Boolean']['output']
}

export type TrackPushNotificationOpenResult = {
  __typename?: 'TrackPushNotificationOpenResult'
  success: Scalars['Boolean']['output']
}

export type TrackPushNotificationPermissionStatusResult = {
  __typename?: 'TrackPushNotificationPermissionStatusResult'
  success: Scalars['Boolean']['output']
}

export type UnseenChatMessages = {
  __typename?: 'UnseenChatMessages'
  unseenMessagesCount: Scalars['Int']['output']
}

export type UpcomingPayment = {
  __typename?: 'UpcomingPayment'
  amountCopy?: Maybe<Scalars['String']['output']>
  badgeCopy?: Maybe<Scalars['String']['output']>
  dueDateCopy?: Maybe<Scalars['String']['output']>
  paymentStatus?: Maybe<UpcomingPaymentStatus>
  weekRangeCopy?: Maybe<Scalars['String']['output']>
}

export enum UpcomingPaymentStatus {
  Active = 'ACTIVE',
  Failed = 'FAILED',
}

export type VideoCall = {
  __typename?: 'VideoCall'
  activeDurationInMS?: Maybe<Scalars['Int']['output']>
  callException?: Maybe<VideoCallException>
  callType: VideoCallType
  cancelledAt?: Maybe<Scalars['DateTime']['output']>
  cancelledBy?: Maybe<VideoCallCancelledBy>
  coach: CoachProfile
  createdAt: Scalars['DateTime']['output']
  endTime: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  joinUrl?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  numberOfParticipants?: Maybe<Scalars['Int']['output']>
  reason?: Maybe<ScheduledVideoCallReason>
  startTime: Scalars['DateTime']['output']
  zoomMeetingID: Scalars['String']['output']
}

export type VideoCallBookingInformation = InProgramVideoCallBookingInformation | IntakeVideoCallBookingInformation

export enum VideoCallCancelledBy {
  Coach = 'COACH',
  Participant = 'PARTICIPANT',
}

export enum VideoCallException {
  LateCancellation = 'LATE_CANCELLATION',
  NoShow = 'NO_SHOW',
  OtherException = 'OTHER_EXCEPTION',
  ProviderIssue = 'PROVIDER_ISSUE',
}

export type VideoCallPatientHistory = PatientHistory & {
  __typename?: 'VideoCallPatientHistory'
  coachID?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  date?: Maybe<Scalars['String']['output']>
  entryID: Scalars['ID']['output']
  source?: Maybe<Scalars['String']['output']>
  type?: Maybe<PatientHistoryType>
  value?: Maybe<Scalars['JSONObject']['output']>
  videoCall: VideoCall
}

export type VideoCallSlot = {
  __typename?: 'VideoCallSlot'
  availabilityCalendarEventID: Scalars['String']['output']
  coachEmail: Scalars['String']['output']
  end: Scalars['DateTime']['output']
  start: Scalars['DateTime']['output']
}

export type VideoCallSlotTaken = {
  __typename?: 'VideoCallSlotTaken'
  availabilityCalendarEventID: Scalars['String']['output']
  callType?: Maybe<VideoCallType>
  startTime: Scalars['DateTime']['output']
}

export enum VideoCallType {
  Intake = 'INTAKE',
  InProgram = 'IN_PROGRAM',
}

export type WeekTheme = {
  __typename?: 'WeekTheme'
  id: Scalars['ID']['output']
  lessons: Array<Lesson>
  title: Scalars['String']['output']
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY',
}
