/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../graphql.generated'

const defaultOptions = {} as const
export type RestartIntakeMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type RestartIntakeMutation = { __typename?: 'Mutation'; restartIntake?: null | null }

export type SetPatientAcceptedMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  programStartDate: Types.Scalars['String']['input']
  source: Types.Scalars['String']['input']
  shipHRVDeviceToPatient: Types.Scalars['Boolean']['input']
}>

export type SetPatientAcceptedMutation = {
  __typename?: 'Mutation'
  setPatientAccepted:
    | {
        __typename?: 'CommonPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename?: 'FinancialEligibilityPatientHistory'
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename?: 'VideoCallPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
}

export type SetPatientDeclinedV2MutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  description?: Types.InputMaybe<Types.Scalars['String']['input']>
  reasons: Array<Types.PatientDeclinedReasonV2> | Types.PatientDeclinedReasonV2
}>

export type SetPatientDeclinedV2Mutation = {
  __typename?: 'Mutation'
  setPatientDeclinedV2:
    | {
        __typename?: 'CommonPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename?: 'FinancialEligibilityPatientHistory'
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename?: 'VideoCallPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
}

export type SetPatientProgramEndMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  engagement: Types.EngagementType
  programEndMetrics: Types.ProgramEndMetricsInput
}>

export type SetPatientProgramEndMutation = {
  __typename?: 'Mutation'
  setPatientProgramEnd:
    | {
        __typename?: 'CommonPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename?: 'FinancialEligibilityPatientHistory'
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename?: 'VideoCallPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
}

export type GetPatientQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPatientQuery = {
  __typename?: 'Query'
  patients: Array<{
    __typename?: 'Patient'
    uid: string
    details?: Types.JSONObject | null
    firstname?: string | null
    lastname?: string | null
  }>
}

export type FullPrivateUserDataFragment = {
  __typename?: 'PrivateUserData'
  id: string
  firstTimeLoginAt?: Date | null
  groupID?: string | null
  isFreeRider: boolean
  programID?: string | null
  programStartDate?: Date | null
  timezone?: string | null
  variables?: { __typename?: 'PrivateUserDataVariables'; hrvDevice?: string | null } | null
}

export type FullPatientFragment = {
  __typename?: 'Patient'
  uid: string
  billingID?: string | null
  birthDate?: Date | null
  credentialingHealthPlan?: string | null
  details?: Types.JSONObject | null
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  preferredName?: string | null
  phone?: string | null
  stateOfResidence?: string | null
  intakeCallTime?: Date | null
  createdAt?: Date | null
  genderIdentity?: string | null
  racialIdentities?: Array<string> | null
  careTeam?: Array<{ __typename?: 'CareTeamMember'; coachID: string; isPrimary: boolean }> | null
  enrollment: {
    __typename?: 'Enrollment'
    id: string
    state: Types.EnrollmentState
    programID: string
    programStartDate?: Date | null
    lastStateTransitionAt: Date
  }
  privateUserData: {
    __typename?: 'PrivateUserData'
    id: string
    firstTimeLoginAt?: Date | null
    groupID?: string | null
    isFreeRider: boolean
    programID?: string | null
    programStartDate?: Date | null
    timezone?: string | null
    variables?: { __typename?: 'PrivateUserDataVariables'; hrvDevice?: string | null } | null
  }
  shippingAddress?: {
    __typename?: 'Address'
    street?: string | null
    city?: string | null
    state?: string | null
    zipCode?: string | null
  } | null
  emergencyContact?: { __typename?: 'EmergencyContact'; name?: string | null; phone?: string | null } | null
}

export type EnrollmentFragment = {
  __typename?: 'Enrollment'
  id: string
  state: Types.EnrollmentState
  programID: string
  programStartDate?: Date | null
  lastStateTransitionAt: Date
}

export type GetPatientCreatedAtQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPatientCreatedAtQuery = {
  __typename?: 'Query'
  patient: { __typename?: 'Patient'; uid: string; createdAt?: Date | null }
}

export type GetFullPatientQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetFullPatientQuery = {
  __typename?: 'Query'
  patient: {
    __typename?: 'Patient'
    uid: string
    billingID?: string | null
    birthDate?: Date | null
    credentialingHealthPlan?: string | null
    details?: Types.JSONObject | null
    email?: string | null
    firstname?: string | null
    lastname?: string | null
    preferredName?: string | null
    phone?: string | null
    stateOfResidence?: string | null
    intakeCallTime?: Date | null
    createdAt?: Date | null
    genderIdentity?: string | null
    racialIdentities?: Array<string> | null
    careTeam?: Array<{ __typename?: 'CareTeamMember'; coachID: string; isPrimary: boolean }> | null
    enrollment: {
      __typename?: 'Enrollment'
      id: string
      state: Types.EnrollmentState
      programID: string
      programStartDate?: Date | null
      lastStateTransitionAt: Date
    }
    privateUserData: {
      __typename?: 'PrivateUserData'
      id: string
      firstTimeLoginAt?: Date | null
      groupID?: string | null
      isFreeRider: boolean
      programID?: string | null
      programStartDate?: Date | null
      timezone?: string | null
      variables?: { __typename?: 'PrivateUserDataVariables'; hrvDevice?: string | null } | null
    }
    shippingAddress?: {
      __typename?: 'Address'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
    } | null
    emergencyContact?: { __typename?: 'EmergencyContact'; name?: string | null; phone?: string | null } | null
  }
}

export type PatientWithAbTestsFragment = {
  __typename?: 'Patient'
  uid: string
  abTests?: Array<{
    __typename?: 'PatientABTest'
    name: string
    possibleGroups: Array<string>
    assignedGroup?: string | null
  }> | null
}

export type GetPatientAbTestsQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPatientAbTestsQuery = {
  __typename?: 'Query'
  patient: {
    __typename?: 'Patient'
    uid: string
    abTests?: Array<{
      __typename?: 'PatientABTest'
      name: string
      possibleGroups: Array<string>
      assignedGroup?: string | null
    }> | null
  }
}

export type PatientAbTestFragment = {
  __typename?: 'PatientABTest'
  name: string
  possibleGroups: Array<string>
  assignedGroup?: string | null
}

export type GetEarliestProgramStartDateQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetEarliestProgramStartDateQuery = {
  __typename?: 'Query'
  patient: { __typename?: 'Patient'; uid: string; earliestProgramStartDate?: string | null }
}

export type UpdatePatientMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  patient?: Types.InputMaybe<Types.PatientInput>
  details?: Types.InputMaybe<Types.PatientDetailsInput>
  privateUserData?: Types.InputMaybe<Types.PrivateUserDataInput>
}>

export type UpdatePatientMutation = {
  __typename?: 'Mutation'
  updatePatient: {
    __typename?: 'Patient'
    uid: string
    billingID?: string | null
    birthDate?: Date | null
    credentialingHealthPlan?: string | null
    details?: Types.JSONObject | null
    email?: string | null
    firstname?: string | null
    lastname?: string | null
    preferredName?: string | null
    phone?: string | null
    stateOfResidence?: string | null
    intakeCallTime?: Date | null
    createdAt?: Date | null
    genderIdentity?: string | null
    racialIdentities?: Array<string> | null
    careTeam?: Array<{ __typename?: 'CareTeamMember'; coachID: string; isPrimary: boolean }> | null
    enrollment: {
      __typename?: 'Enrollment'
      id: string
      state: Types.EnrollmentState
      programID: string
      programStartDate?: Date | null
      lastStateTransitionAt: Date
    }
    privateUserData: {
      __typename?: 'PrivateUserData'
      id: string
      firstTimeLoginAt?: Date | null
      groupID?: string | null
      isFreeRider: boolean
      programID?: string | null
      programStartDate?: Date | null
      timezone?: string | null
      variables?: { __typename?: 'PrivateUserDataVariables'; hrvDevice?: string | null } | null
    }
    shippingAddress?: {
      __typename?: 'Address'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
    } | null
    emergencyContact?: { __typename?: 'EmergencyContact'; name?: string | null; phone?: string | null } | null
  }
}

export type UpdateAbTestGroupsMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  abTests: Array<Types.PatientAbTestInput> | Types.PatientAbTestInput
}>

export type UpdateAbTestGroupsMutation = {
  __typename?: 'Mutation'
  updateABTestGroups: {
    __typename?: 'Patient'
    uid: string
    abTests?: Array<{
      __typename?: 'PatientABTest'
      name: string
      possibleGroups: Array<string>
      assignedGroup?: string | null
    }> | null
  }
}

export type PatientWithEnrollmentFragment = {
  __typename?: 'Patient'
  uid: string
  enrollment: {
    __typename?: 'Enrollment'
    id: string
    state: Types.EnrollmentState
    programID: string
    programStartDate?: Date | null
    lastStateTransitionAt: Date
  }
}

export type GetPatientWithEnrollmentQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPatientWithEnrollmentQuery = {
  __typename?: 'Query'
  patient: {
    __typename?: 'Patient'
    uid: string
    enrollment: {
      __typename?: 'Enrollment'
      id: string
      state: Types.EnrollmentState
      programID: string
      programStartDate?: Date | null
      lastStateTransitionAt: Date
    }
  }
}

export type PatientWithCareTeamFragment = {
  __typename?: 'Patient'
  uid: string
  firstname?: string | null
  lastname?: string | null
  careTeam?: Array<{ __typename?: 'CareTeamMember'; coachID: string; isPrimary: boolean }> | null
}

export type GetPatientsWithCareTeamQueryVariables = Types.Exact<{
  uids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
}>

export type GetPatientsWithCareTeamQuery = {
  __typename?: 'Query'
  patients: Array<{
    __typename?: 'Patient'
    uid: string
    firstname?: string | null
    lastname?: string | null
    careTeam?: Array<{ __typename?: 'CareTeamMember'; coachID: string; isPrimary: boolean }> | null
  }>
}

export type GetPatientWithIndicatorsQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  includeIndicators: Types.Scalars['Boolean']['input']
  responsesCountStart: Types.Scalars['Date']['input']
  responsesCountEnd: Types.Scalars['Date']['input']
}>

export type GetPatientWithIndicatorsQuery = {
  __typename?: 'Query'
  patients: Array<{
    __typename?: 'Patient'
    uid: string
    firstname?: string | null
    lastname?: string | null
    indicators?: {
      __typename?: 'PatientIndicators'
      completedResponses?: Array<{
        __typename?: 'PatientCompletedResponsesIndicator'
        date: Date
        count: number
      }> | null
      engagement?: {
        __typename?: 'PatientEngagementIndicator'
        type: Types.EngagementType
        completedIntros: number
        totalPossibleIntros: number
      } | null
    } | null
  }>
}

export type ParticipantWithTagsFragment = {
  __typename?: 'Patient'
  uid: string
  tags?: Array<{ __typename?: 'PatientTag'; id: string; name: string }> | null
}

export type GetPatientWithTagsQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPatientWithTagsQuery = {
  __typename?: 'Query'
  patient: {
    __typename?: 'Patient'
    uid: string
    tags?: Array<{ __typename?: 'PatientTag'; id: string; name: string }> | null
  }
}

export type AddTagToParticipantMutationVariables = Types.Exact<{
  tagID: Types.Scalars['ID']['input']
  uid: Types.Scalars['ID']['input']
}>

export type AddTagToParticipantMutation = {
  __typename?: 'Mutation'
  addTagToPatient?: { __typename?: 'AddTagToPatientAction'; uid: string; tagID: string } | null
}

export type RemoveTagFromParticipantMutationVariables = Types.Exact<{
  tagID: Types.Scalars['ID']['input']
  uid: Types.Scalars['ID']['input']
}>

export type RemoveTagFromParticipantMutation = { __typename?: 'Mutation'; removeTagFromPatient?: null | null }

export type UpdateProgramStartDateMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  programStartDate: Types.Scalars['String']['input']
}>

export type UpdateProgramStartDateMutation = {
  __typename?: 'Mutation'
  updateProgramStartDate?: {
    __typename?: 'Enrollment'
    id: string
    programStartDate?: Date | null
    programID: string
    state: Types.EnrollmentState
  } | null
}

export type RestartParticipantProgramMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  programStartDate: Types.Scalars['String']['input']
  description: Types.Scalars['String']['input']
}>

export type RestartParticipantProgramMutation = {
  __typename?: 'Mutation'
  restartPatientInProgram: {
    __typename?: 'CreateEnrollmentAction'
    uid: string
    patient: { __typename?: 'Patient'; enrollment: { __typename?: 'Enrollment'; programStartDate?: Date | null } }
  }
}

export const EnrollmentFragmentDoc = gql`
  fragment Enrollment on Enrollment {
    id
    state
    programID
    programStartDate
    lastStateTransitionAt
    programStartDate
  }
`
export const FullPrivateUserDataFragmentDoc = gql`
  fragment FullPrivateUserData on PrivateUserData {
    id
    firstTimeLoginAt
    groupID
    isFreeRider
    programID
    programStartDate
    timezone
    variables {
      hrvDevice
    }
  }
`
export const FullPatientFragmentDoc = gql`
  fragment FullPatient on Patient {
    uid
    billingID
    birthDate
    credentialingHealthPlan
    details
    email
    firstname
    lastname
    preferredName
    phone
    stateOfResidence
    careTeam {
      coachID
      isPrimary
    }
    enrollment {
      ...Enrollment
    }
    privateUserData {
      ...FullPrivateUserData
    }
    intakeCallTime
    createdAt
    genderIdentity
    shippingAddress {
      street
      city
      state
      zipCode
    }
    emergencyContact {
      name
      phone
    }
    racialIdentities
  }
  ${EnrollmentFragmentDoc}
  ${FullPrivateUserDataFragmentDoc}
`
export const PatientAbTestFragmentDoc = gql`
  fragment PatientABTest on PatientABTest {
    name
    possibleGroups
    assignedGroup
  }
`
export const PatientWithAbTestsFragmentDoc = gql`
  fragment PatientWithABTests on Patient {
    uid
    abTests {
      ...PatientABTest
    }
  }
  ${PatientAbTestFragmentDoc}
`
export const PatientWithEnrollmentFragmentDoc = gql`
  fragment PatientWithEnrollment on Patient {
    uid
    enrollment {
      ...Enrollment
    }
  }
  ${EnrollmentFragmentDoc}
`
export const PatientWithCareTeamFragmentDoc = gql`
  fragment PatientWithCareTeam on Patient {
    uid
    firstname
    lastname
    careTeam {
      coachID
      isPrimary
    }
  }
`
export const ParticipantWithTagsFragmentDoc = gql`
  fragment ParticipantWithTags on Patient {
    uid
    tags {
      id
      name
    }
  }
`
export const RestartIntakeDocument = gql`
  mutation restartIntake($uid: ID!) {
    restartIntake(uid: $uid)
  }
`
export type RestartIntakeMutationFn = Apollo.MutationFunction<RestartIntakeMutation, RestartIntakeMutationVariables>

/**
 * __useRestartIntakeMutation__
 *
 * To run a mutation, you first call `useRestartIntakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartIntakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartIntakeMutation, { data, loading, error }] = useRestartIntakeMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useRestartIntakeMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartIntakeMutation, RestartIntakeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RestartIntakeMutation, RestartIntakeMutationVariables>(RestartIntakeDocument, options)
}
export type RestartIntakeMutationHookResult = ReturnType<typeof useRestartIntakeMutation>
export type RestartIntakeMutationResult = Apollo.MutationResult<RestartIntakeMutation>
export type RestartIntakeMutationOptions = Apollo.BaseMutationOptions<
  RestartIntakeMutation,
  RestartIntakeMutationVariables
>
export const SetPatientAcceptedDocument = gql`
  mutation setPatientAccepted(
    $uid: ID!
    $programStartDate: String!
    $source: String!
    $shipHRVDeviceToPatient: Boolean!
  ) {
    setPatientAccepted(
      uid: $uid
      programStartDate: $programStartDate
      source: $source
      shipHRVDeviceToPatient: $shipHRVDeviceToPatient
    ) {
      entryID
      coachID
      createdAt
      date
      source
      type
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`
export type SetPatientAcceptedMutationFn = Apollo.MutationFunction<
  SetPatientAcceptedMutation,
  SetPatientAcceptedMutationVariables
>

/**
 * __useSetPatientAcceptedMutation__
 *
 * To run a mutation, you first call `useSetPatientAcceptedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientAcceptedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientAcceptedMutation, { data, loading, error }] = useSetPatientAcceptedMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      programStartDate: // value for 'programStartDate'
 *      source: // value for 'source'
 *      shipHRVDeviceToPatient: // value for 'shipHRVDeviceToPatient'
 *   },
 * });
 */
export function useSetPatientAcceptedMutation(
  baseOptions?: Apollo.MutationHookOptions<SetPatientAcceptedMutation, SetPatientAcceptedMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetPatientAcceptedMutation, SetPatientAcceptedMutationVariables>(
    SetPatientAcceptedDocument,
    options
  )
}
export type SetPatientAcceptedMutationHookResult = ReturnType<typeof useSetPatientAcceptedMutation>
export type SetPatientAcceptedMutationResult = Apollo.MutationResult<SetPatientAcceptedMutation>
export type SetPatientAcceptedMutationOptions = Apollo.BaseMutationOptions<
  SetPatientAcceptedMutation,
  SetPatientAcceptedMutationVariables
>
export const SetPatientDeclinedV2Document = gql`
  mutation setPatientDeclinedV2($uid: ID!, $description: String, $reasons: [PatientDeclinedReasonV2!]!) {
    setPatientDeclinedV2(uid: $uid, description: $description, reasons: $reasons) {
      entryID
      coachID
      createdAt
      date
      source
      type
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`
export type SetPatientDeclinedV2MutationFn = Apollo.MutationFunction<
  SetPatientDeclinedV2Mutation,
  SetPatientDeclinedV2MutationVariables
>

/**
 * __useSetPatientDeclinedV2Mutation__
 *
 * To run a mutation, you first call `useSetPatientDeclinedV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientDeclinedV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientDeclinedV2Mutation, { data, loading, error }] = useSetPatientDeclinedV2Mutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      description: // value for 'description'
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useSetPatientDeclinedV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<SetPatientDeclinedV2Mutation, SetPatientDeclinedV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetPatientDeclinedV2Mutation, SetPatientDeclinedV2MutationVariables>(
    SetPatientDeclinedV2Document,
    options
  )
}
export type SetPatientDeclinedV2MutationHookResult = ReturnType<typeof useSetPatientDeclinedV2Mutation>
export type SetPatientDeclinedV2MutationResult = Apollo.MutationResult<SetPatientDeclinedV2Mutation>
export type SetPatientDeclinedV2MutationOptions = Apollo.BaseMutationOptions<
  SetPatientDeclinedV2Mutation,
  SetPatientDeclinedV2MutationVariables
>
export const SetPatientProgramEndDocument = gql`
  mutation setPatientProgramEnd($uid: ID!, $engagement: EngagementType!, $programEndMetrics: ProgramEndMetricsInput!) {
    setPatientProgramEnd(uid: $uid, engagement: $engagement, programEndMetrics: $programEndMetrics) {
      entryID
      coachID
      createdAt
      date
      source
      type
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`
export type SetPatientProgramEndMutationFn = Apollo.MutationFunction<
  SetPatientProgramEndMutation,
  SetPatientProgramEndMutationVariables
>

/**
 * __useSetPatientProgramEndMutation__
 *
 * To run a mutation, you first call `useSetPatientProgramEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientProgramEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientProgramEndMutation, { data, loading, error }] = useSetPatientProgramEndMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      engagement: // value for 'engagement'
 *      programEndMetrics: // value for 'programEndMetrics'
 *   },
 * });
 */
export function useSetPatientProgramEndMutation(
  baseOptions?: Apollo.MutationHookOptions<SetPatientProgramEndMutation, SetPatientProgramEndMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetPatientProgramEndMutation, SetPatientProgramEndMutationVariables>(
    SetPatientProgramEndDocument,
    options
  )
}
export type SetPatientProgramEndMutationHookResult = ReturnType<typeof useSetPatientProgramEndMutation>
export type SetPatientProgramEndMutationResult = Apollo.MutationResult<SetPatientProgramEndMutation>
export type SetPatientProgramEndMutationOptions = Apollo.BaseMutationOptions<
  SetPatientProgramEndMutation,
  SetPatientProgramEndMutationVariables
>
export const GetPatientDocument = gql`
  query getPatient($uid: ID!) {
    patients(uids: [$uid]) {
      uid
      details
      firstname
      lastname
    }
  }
`

/**
 * __useGetPatientQuery__
 *
 * To run a query within a React component, call `useGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPatientQuery(baseOptions: Apollo.QueryHookOptions<GetPatientQuery, GetPatientQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientQuery, GetPatientQueryVariables>(GetPatientDocument, options)
}
export function useGetPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientQuery, GetPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientQuery, GetPatientQueryVariables>(GetPatientDocument, options)
}
export type GetPatientQueryHookResult = ReturnType<typeof useGetPatientQuery>
export type GetPatientLazyQueryHookResult = ReturnType<typeof useGetPatientLazyQuery>
export type GetPatientQueryResult = Apollo.QueryResult<GetPatientQuery, GetPatientQueryVariables>
export const GetPatientCreatedAtDocument = gql`
  query getPatientCreatedAt($uid: ID!) {
    patient(uid: $uid) {
      uid
      createdAt
    }
  }
`

/**
 * __useGetPatientCreatedAtQuery__
 *
 * To run a query within a React component, call `useGetPatientCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCreatedAtQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPatientCreatedAtQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientCreatedAtQuery, GetPatientCreatedAtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientCreatedAtQuery, GetPatientCreatedAtQueryVariables>(
    GetPatientCreatedAtDocument,
    options
  )
}
export function useGetPatientCreatedAtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCreatedAtQuery, GetPatientCreatedAtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientCreatedAtQuery, GetPatientCreatedAtQueryVariables>(
    GetPatientCreatedAtDocument,
    options
  )
}
export type GetPatientCreatedAtQueryHookResult = ReturnType<typeof useGetPatientCreatedAtQuery>
export type GetPatientCreatedAtLazyQueryHookResult = ReturnType<typeof useGetPatientCreatedAtLazyQuery>
export type GetPatientCreatedAtQueryResult = Apollo.QueryResult<
  GetPatientCreatedAtQuery,
  GetPatientCreatedAtQueryVariables
>
export const GetFullPatientDocument = gql`
  query getFullPatient($uid: ID!) {
    patient(uid: $uid) {
      ...FullPatient
    }
  }
  ${FullPatientFragmentDoc}
`

/**
 * __useGetFullPatientQuery__
 *
 * To run a query within a React component, call `useGetFullPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullPatientQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetFullPatientQuery(
  baseOptions: Apollo.QueryHookOptions<GetFullPatientQuery, GetFullPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFullPatientQuery, GetFullPatientQueryVariables>(GetFullPatientDocument, options)
}
export function useGetFullPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFullPatientQuery, GetFullPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFullPatientQuery, GetFullPatientQueryVariables>(GetFullPatientDocument, options)
}
export type GetFullPatientQueryHookResult = ReturnType<typeof useGetFullPatientQuery>
export type GetFullPatientLazyQueryHookResult = ReturnType<typeof useGetFullPatientLazyQuery>
export type GetFullPatientQueryResult = Apollo.QueryResult<GetFullPatientQuery, GetFullPatientQueryVariables>
export const GetPatientAbTestsDocument = gql`
  query getPatientABTests($uid: ID!) {
    patient(uid: $uid) {
      ...PatientWithABTests
    }
  }
  ${PatientWithAbTestsFragmentDoc}
`

/**
 * __useGetPatientAbTestsQuery__
 *
 * To run a query within a React component, call `useGetPatientAbTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientAbTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientAbTestsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPatientAbTestsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientAbTestsQuery, GetPatientAbTestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientAbTestsQuery, GetPatientAbTestsQueryVariables>(GetPatientAbTestsDocument, options)
}
export function useGetPatientAbTestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientAbTestsQuery, GetPatientAbTestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientAbTestsQuery, GetPatientAbTestsQueryVariables>(
    GetPatientAbTestsDocument,
    options
  )
}
export type GetPatientAbTestsQueryHookResult = ReturnType<typeof useGetPatientAbTestsQuery>
export type GetPatientAbTestsLazyQueryHookResult = ReturnType<typeof useGetPatientAbTestsLazyQuery>
export type GetPatientAbTestsQueryResult = Apollo.QueryResult<GetPatientAbTestsQuery, GetPatientAbTestsQueryVariables>
export const GetEarliestProgramStartDateDocument = gql`
  query getEarliestProgramStartDate($uid: ID!) {
    patient(uid: $uid) {
      uid
      earliestProgramStartDate
    }
  }
`

/**
 * __useGetEarliestProgramStartDateQuery__
 *
 * To run a query within a React component, call `useGetEarliestProgramStartDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarliestProgramStartDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarliestProgramStartDateQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetEarliestProgramStartDateQuery(
  baseOptions: Apollo.QueryHookOptions<GetEarliestProgramStartDateQuery, GetEarliestProgramStartDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEarliestProgramStartDateQuery, GetEarliestProgramStartDateQueryVariables>(
    GetEarliestProgramStartDateDocument,
    options
  )
}
export function useGetEarliestProgramStartDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEarliestProgramStartDateQuery, GetEarliestProgramStartDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEarliestProgramStartDateQuery, GetEarliestProgramStartDateQueryVariables>(
    GetEarliestProgramStartDateDocument,
    options
  )
}
export type GetEarliestProgramStartDateQueryHookResult = ReturnType<typeof useGetEarliestProgramStartDateQuery>
export type GetEarliestProgramStartDateLazyQueryHookResult = ReturnType<typeof useGetEarliestProgramStartDateLazyQuery>
export type GetEarliestProgramStartDateQueryResult = Apollo.QueryResult<
  GetEarliestProgramStartDateQuery,
  GetEarliestProgramStartDateQueryVariables
>
export const UpdatePatientDocument = gql`
  mutation updatePatient(
    $uid: ID!
    $patient: PatientInput
    $details: PatientDetailsInput
    $privateUserData: PrivateUserDataInput
  ) {
    updatePatient(uid: $uid, patient: $patient, details: $details, privateUserData: $privateUserData) {
      ...FullPatient
    }
  }
  ${FullPatientFragmentDoc}
`
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      patient: // value for 'patient'
 *      details: // value for 'details'
 *      privateUserData: // value for 'privateUserData'
 *   },
 * });
 */
export function useUpdatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options)
}
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>
export const UpdateAbTestGroupsDocument = gql`
  mutation updateABTestGroups($uid: ID!, $abTests: [PatientABTestInput!]!) {
    updateABTestGroups(uid: $uid, abTests: $abTests) {
      ...PatientWithABTests
    }
  }
  ${PatientWithAbTestsFragmentDoc}
`
export type UpdateAbTestGroupsMutationFn = Apollo.MutationFunction<
  UpdateAbTestGroupsMutation,
  UpdateAbTestGroupsMutationVariables
>

/**
 * __useUpdateAbTestGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateAbTestGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAbTestGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbTestGroupsMutation, { data, loading, error }] = useUpdateAbTestGroupsMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      abTests: // value for 'abTests'
 *   },
 * });
 */
export function useUpdateAbTestGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAbTestGroupsMutation, UpdateAbTestGroupsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAbTestGroupsMutation, UpdateAbTestGroupsMutationVariables>(
    UpdateAbTestGroupsDocument,
    options
  )
}
export type UpdateAbTestGroupsMutationHookResult = ReturnType<typeof useUpdateAbTestGroupsMutation>
export type UpdateAbTestGroupsMutationResult = Apollo.MutationResult<UpdateAbTestGroupsMutation>
export type UpdateAbTestGroupsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAbTestGroupsMutation,
  UpdateAbTestGroupsMutationVariables
>
export const GetPatientWithEnrollmentDocument = gql`
  query getPatientWithEnrollment($uid: ID!) {
    patient(uid: $uid) {
      ...PatientWithEnrollment
    }
  }
  ${PatientWithEnrollmentFragmentDoc}
`

/**
 * __useGetPatientWithEnrollmentQuery__
 *
 * To run a query within a React component, call `useGetPatientWithEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWithEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWithEnrollmentQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPatientWithEnrollmentQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientWithEnrollmentQuery, GetPatientWithEnrollmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientWithEnrollmentQuery, GetPatientWithEnrollmentQueryVariables>(
    GetPatientWithEnrollmentDocument,
    options
  )
}
export function useGetPatientWithEnrollmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWithEnrollmentQuery, GetPatientWithEnrollmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientWithEnrollmentQuery, GetPatientWithEnrollmentQueryVariables>(
    GetPatientWithEnrollmentDocument,
    options
  )
}
export type GetPatientWithEnrollmentQueryHookResult = ReturnType<typeof useGetPatientWithEnrollmentQuery>
export type GetPatientWithEnrollmentLazyQueryHookResult = ReturnType<typeof useGetPatientWithEnrollmentLazyQuery>
export type GetPatientWithEnrollmentQueryResult = Apollo.QueryResult<
  GetPatientWithEnrollmentQuery,
  GetPatientWithEnrollmentQueryVariables
>
export const GetPatientsWithCareTeamDocument = gql`
  query getPatientsWithCareTeam($uids: [ID!]!) {
    patients(uids: $uids) {
      ...PatientWithCareTeam
    }
  }
  ${PatientWithCareTeamFragmentDoc}
`

/**
 * __useGetPatientsWithCareTeamQuery__
 *
 * To run a query within a React component, call `useGetPatientsWithCareTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsWithCareTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsWithCareTeamQuery({
 *   variables: {
 *      uids: // value for 'uids'
 *   },
 * });
 */
export function useGetPatientsWithCareTeamQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientsWithCareTeamQuery, GetPatientsWithCareTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientsWithCareTeamQuery, GetPatientsWithCareTeamQueryVariables>(
    GetPatientsWithCareTeamDocument,
    options
  )
}
export function useGetPatientsWithCareTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsWithCareTeamQuery, GetPatientsWithCareTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientsWithCareTeamQuery, GetPatientsWithCareTeamQueryVariables>(
    GetPatientsWithCareTeamDocument,
    options
  )
}
export type GetPatientsWithCareTeamQueryHookResult = ReturnType<typeof useGetPatientsWithCareTeamQuery>
export type GetPatientsWithCareTeamLazyQueryHookResult = ReturnType<typeof useGetPatientsWithCareTeamLazyQuery>
export type GetPatientsWithCareTeamQueryResult = Apollo.QueryResult<
  GetPatientsWithCareTeamQuery,
  GetPatientsWithCareTeamQueryVariables
>
export const GetPatientWithIndicatorsDocument = gql`
  query getPatientWithIndicators(
    $uid: ID!
    $includeIndicators: Boolean!
    $responsesCountStart: Date!
    $responsesCountEnd: Date!
  ) {
    patients(uids: [$uid]) {
      uid
      firstname
      lastname
      indicators @include(if: $includeIndicators) {
        completedResponses(startDate: $responsesCountStart, endDate: $responsesCountEnd) {
          date
          count
        }
        engagement {
          type
          completedIntros
          totalPossibleIntros
        }
      }
    }
  }
`

/**
 * __useGetPatientWithIndicatorsQuery__
 *
 * To run a query within a React component, call `useGetPatientWithIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWithIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWithIndicatorsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      includeIndicators: // value for 'includeIndicators'
 *      responsesCountStart: // value for 'responsesCountStart'
 *      responsesCountEnd: // value for 'responsesCountEnd'
 *   },
 * });
 */
export function useGetPatientWithIndicatorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientWithIndicatorsQuery, GetPatientWithIndicatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientWithIndicatorsQuery, GetPatientWithIndicatorsQueryVariables>(
    GetPatientWithIndicatorsDocument,
    options
  )
}
export function useGetPatientWithIndicatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWithIndicatorsQuery, GetPatientWithIndicatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientWithIndicatorsQuery, GetPatientWithIndicatorsQueryVariables>(
    GetPatientWithIndicatorsDocument,
    options
  )
}
export type GetPatientWithIndicatorsQueryHookResult = ReturnType<typeof useGetPatientWithIndicatorsQuery>
export type GetPatientWithIndicatorsLazyQueryHookResult = ReturnType<typeof useGetPatientWithIndicatorsLazyQuery>
export type GetPatientWithIndicatorsQueryResult = Apollo.QueryResult<
  GetPatientWithIndicatorsQuery,
  GetPatientWithIndicatorsQueryVariables
>
export const GetPatientWithTagsDocument = gql`
  query getPatientWithTags($uid: ID!) {
    patient(uid: $uid) {
      ...ParticipantWithTags
    }
  }
  ${ParticipantWithTagsFragmentDoc}
`

/**
 * __useGetPatientWithTagsQuery__
 *
 * To run a query within a React component, call `useGetPatientWithTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWithTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWithTagsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPatientWithTagsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientWithTagsQuery, GetPatientWithTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientWithTagsQuery, GetPatientWithTagsQueryVariables>(GetPatientWithTagsDocument, options)
}
export function useGetPatientWithTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWithTagsQuery, GetPatientWithTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientWithTagsQuery, GetPatientWithTagsQueryVariables>(
    GetPatientWithTagsDocument,
    options
  )
}
export type GetPatientWithTagsQueryHookResult = ReturnType<typeof useGetPatientWithTagsQuery>
export type GetPatientWithTagsLazyQueryHookResult = ReturnType<typeof useGetPatientWithTagsLazyQuery>
export type GetPatientWithTagsQueryResult = Apollo.QueryResult<
  GetPatientWithTagsQuery,
  GetPatientWithTagsQueryVariables
>
export const AddTagToParticipantDocument = gql`
  mutation addTagToParticipant($tagID: ID!, $uid: ID!) {
    addTagToPatient(tagID: $tagID, uid: $uid) {
      uid
      tagID
    }
  }
`
export type AddTagToParticipantMutationFn = Apollo.MutationFunction<
  AddTagToParticipantMutation,
  AddTagToParticipantMutationVariables
>

/**
 * __useAddTagToParticipantMutation__
 *
 * To run a mutation, you first call `useAddTagToParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToParticipantMutation, { data, loading, error }] = useAddTagToParticipantMutation({
 *   variables: {
 *      tagID: // value for 'tagID'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useAddTagToParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTagToParticipantMutation, AddTagToParticipantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddTagToParticipantMutation, AddTagToParticipantMutationVariables>(
    AddTagToParticipantDocument,
    options
  )
}
export type AddTagToParticipantMutationHookResult = ReturnType<typeof useAddTagToParticipantMutation>
export type AddTagToParticipantMutationResult = Apollo.MutationResult<AddTagToParticipantMutation>
export type AddTagToParticipantMutationOptions = Apollo.BaseMutationOptions<
  AddTagToParticipantMutation,
  AddTagToParticipantMutationVariables
>
export const RemoveTagFromParticipantDocument = gql`
  mutation removeTagFromParticipant($tagID: ID!, $uid: ID!) {
    removeTagFromPatient(tagID: $tagID, uid: $uid)
  }
`
export type RemoveTagFromParticipantMutationFn = Apollo.MutationFunction<
  RemoveTagFromParticipantMutation,
  RemoveTagFromParticipantMutationVariables
>

/**
 * __useRemoveTagFromParticipantMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromParticipantMutation, { data, loading, error }] = useRemoveTagFromParticipantMutation({
 *   variables: {
 *      tagID: // value for 'tagID'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useRemoveTagFromParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTagFromParticipantMutation, RemoveTagFromParticipantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveTagFromParticipantMutation, RemoveTagFromParticipantMutationVariables>(
    RemoveTagFromParticipantDocument,
    options
  )
}
export type RemoveTagFromParticipantMutationHookResult = ReturnType<typeof useRemoveTagFromParticipantMutation>
export type RemoveTagFromParticipantMutationResult = Apollo.MutationResult<RemoveTagFromParticipantMutation>
export type RemoveTagFromParticipantMutationOptions = Apollo.BaseMutationOptions<
  RemoveTagFromParticipantMutation,
  RemoveTagFromParticipantMutationVariables
>
export const UpdateProgramStartDateDocument = gql`
  mutation updateProgramStartDate($uid: ID!, $programStartDate: String!) {
    updateProgramStartDate(uid: $uid, programStartDate: $programStartDate) {
      id
      programStartDate
      programID
      state
    }
  }
`
export type UpdateProgramStartDateMutationFn = Apollo.MutationFunction<
  UpdateProgramStartDateMutation,
  UpdateProgramStartDateMutationVariables
>

/**
 * __useUpdateProgramStartDateMutation__
 *
 * To run a mutation, you first call `useUpdateProgramStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramStartDateMutation, { data, loading, error }] = useUpdateProgramStartDateMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      programStartDate: // value for 'programStartDate'
 *   },
 * });
 */
export function useUpdateProgramStartDateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProgramStartDateMutation, UpdateProgramStartDateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProgramStartDateMutation, UpdateProgramStartDateMutationVariables>(
    UpdateProgramStartDateDocument,
    options
  )
}
export type UpdateProgramStartDateMutationHookResult = ReturnType<typeof useUpdateProgramStartDateMutation>
export type UpdateProgramStartDateMutationResult = Apollo.MutationResult<UpdateProgramStartDateMutation>
export type UpdateProgramStartDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateProgramStartDateMutation,
  UpdateProgramStartDateMutationVariables
>
export const RestartParticipantProgramDocument = gql`
  mutation restartParticipantProgram($uid: ID!, $programStartDate: String!, $description: String!) {
    restartPatientInProgram(uid: $uid, programStartDate: $programStartDate, description: $description) {
      uid
      patient {
        enrollment {
          programStartDate
        }
      }
    }
  }
`
export type RestartParticipantProgramMutationFn = Apollo.MutationFunction<
  RestartParticipantProgramMutation,
  RestartParticipantProgramMutationVariables
>

/**
 * __useRestartParticipantProgramMutation__
 *
 * To run a mutation, you first call `useRestartParticipantProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartParticipantProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartParticipantProgramMutation, { data, loading, error }] = useRestartParticipantProgramMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      programStartDate: // value for 'programStartDate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useRestartParticipantProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartParticipantProgramMutation,
    RestartParticipantProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RestartParticipantProgramMutation, RestartParticipantProgramMutationVariables>(
    RestartParticipantProgramDocument,
    options
  )
}
export type RestartParticipantProgramMutationHookResult = ReturnType<typeof useRestartParticipantProgramMutation>
export type RestartParticipantProgramMutationResult = Apollo.MutationResult<RestartParticipantProgramMutation>
export type RestartParticipantProgramMutationOptions = Apollo.BaseMutationOptions<
  RestartParticipantProgramMutation,
  RestartParticipantProgramMutationVariables
>
