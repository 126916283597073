import React from 'react'
import { Field } from 'formik'
import { Box, Card, CardContent, Grid, MenuItem, Tooltip, Typography } from '@mui/material'
import { useToasts, AppearanceTypes } from 'react-toast-notifications'
import { Autosave, CustomCheckbox, FormDatePicker, CustomTextField, RequestForm } from 'src/form'
import { SourceField } from 'src/legacy/components/fields'
import { ALL_STATES, useUpdateParticipantData } from 'src/participant'
import { FullPatientFragment } from 'src/participant/patientQueries.generated'
import ReferralTypeField from './ReferralTypeField'
import { PatientSchema } from './PatientSchema'
import { fromApiValueToFormValue, fromFormValueToApiValue } from './transform'
import { PATIENT_RECORD_SCHEMA } from './patient-record-schema'
import { GenderIdentityField } from './GenderIdentityField'
import { RacialIdentityField } from './RacialIdentityField'

type Props = {
  uid: string
  isLoading: boolean
  patient: FullPatientFragment | undefined
}

const ParticipantRecordForm: React.FunctionComponent<Props> = (props) => {
  const { uid, patient, isLoading } = props

  const updateParticipantData = useUpdateParticipantData(uid)

  const initialValues: PatientSchema = React.useMemo(() => fromApiValueToFormValue(patient), [patient])

  const { addToast } = useToasts()
  const onSuccess = () => {
    const successMessage = 'Participant details updated'
    const toastOptions = {
      // type assertion used, as library typing is out of date. autoDismissTimeout option works, but library typings do not support it in the Options type, thus hacking around it with the "as" assertion
      appearance: 'success' as AppearanceTypes,
      autoDismissTimeout: 3000,
    }
    addToast(successMessage, toastOptions)
  }

  return (
    <RequestForm
      onSubmit={(values) => {
        const apiValue = fromFormValueToApiValue(values)
        return updateParticipantData(apiValue.patient, apiValue.privateUserData)
      }}
      resetAfterSuccess={false}
      validationSchema={PATIENT_RECORD_SCHEMA}
      initialValues={initialValues}
      isLoading={isLoading || !patient}
      onSuccess={onSuccess}
      render={() => (
        <Card>
          <Autosave debounceMs={1800} />
          <CardContent>
            <Box mb={1} mt={3}>
              <Typography variant="h3">Basic details</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="First name" name="patient.firstname" />
              </Grid>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="Last name" name="patient.lastname" />
              </Grid>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="Preferred name" name="patient.preferredName" />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="Email" name="patient.email" />
              </Grid>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="Phone" name="patient.phone" />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Participating employer"
                  name="patient.details.employer"
                />
              </Grid>
              <Grid item xs>
                <SourceField name="patient.details.source" />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <GenderIdentityField />
              </Grid>
              <Grid item xs={6}>
                <RacialIdentityField />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs>
                <Field component={FormDatePicker} fullWidth label="Birthdate" name="patient.birthDate" />
              </Grid>
              <Grid item xs>
                <Field
                  component={CustomCheckbox}
                  fullWidth
                  label="Is a dependent or spouse?"
                  name="patient.details.isDependent"
                />
              </Grid>
            </Grid>

            <Box mb={1} mt={5}>
              <Tooltip
                title="This details the credentials and license that the therapist needs to have for this participant."
                enterDelay={500}
                placement="top-start"
              >
                <Typography variant="h3">Location and credential information</Typography>
              </Tooltip>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Physical location"
                  name="patient.stateOfResidence"
                  type="select"
                >
                  {ALL_STATES.map(({ code, name }) => (
                    <MenuItem value={code} key={code}>
                      {name} ({code})
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  disabled
                  fullWidth
                  label="Required health plan credentialing"
                  name="patient.credentialingHealthPlan"
                />
              </Grid>
            </Grid>

            <Box mb={1} mt={5}>
              <Typography variant="h3">Shipping information</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Shipping address"
                  name="patient.shippingAddress.street"
                />
              </Grid>
              <Grid item xs />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="City" name="patient.shippingAddress.city" />
              </Grid>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="State" name="patient.shippingAddress.state" />
              </Grid>
              <Grid item xs>
                <Field component={CustomTextField} fullWidth label="Zip" name="patient.shippingAddress.zipCode" />
              </Grid>
            </Grid>

            <Box mb={1} mt={5}>
              <Typography variant="h3">Emergency contact information</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Emergency contact name"
                  name="patient.emergencyContact.name"
                />
              </Grid>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Emergency contact phone"
                  name="patient.emergencyContact.phone"
                />
              </Grid>
            </Grid>

            <Box mb={1} mt={5}>
              <Typography variant="h3">Billing information</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Insurance Member ID"
                  name="patient.details.memberID"
                />
              </Grid>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Insurance Group ID"
                  name="patient.details.groupID"
                />
              </Grid>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="External Reference ID"
                  name="patient.details.externalReferenceID"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs>
                <Field component={CustomTextField} disabled fullWidth label="Billing ID" name="patient.billingID" />
              </Grid>
              <Grid item xs>
                <ReferralTypeField />
              </Grid>
              <Grid item xs>
                <Field
                  component={CustomTextField}
                  fullWidth
                  label="Referral name"
                  name="patient.details.referralName"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs>
                <Field component={CustomCheckbox} fullWidth label="Is free rider" name="privateUserData.isFreeRider" />
              </Grid>
              <Grid item xs>
                <Field
                  component={CustomCheckbox}
                  fullWidth
                  label="Excluded from billing and customer reporting"
                  name="patient.details.excludedFromCalculations"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    />
  )
}

export default ParticipantRecordForm
