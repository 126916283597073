import * as Yup from 'yup'
import { PatientSchema } from './PatientSchema'
import './yup.phoneNumberValidator'

export const PATIENT_RECORD_SCHEMA: Yup.ObjectSchema<PatientSchema> = Yup.object({
  patient: Yup.object({
    birthDate: Yup.string(),
    firstname: Yup.string().nullable(),
    lastname: Yup.string().nullable(),
    email: Yup.string().lowercase().email().required(),
    phone: Yup.string().nullable().phoneNumber(),
    stateOfResidence: Yup.string().nullable(),
    preferredName: Yup.string().nullable(),
    genderIdentity: Yup.string().nullable(),
    racialIdentities: Yup.array().of(Yup.string()).nullable(),
    emergencyContact: Yup.object({
      name: Yup.string().nullable(),
      phone: Yup.string().nullable(),
    }).nullable(),
    details: Yup.object({
      source: Yup.string(),
      employer: Yup.string(),
      sex: Yup.string(),
      isDependent: Yup.boolean().default(false),
      memberID: Yup.string(),
      groupID: Yup.string(),
      externalReferenceID: Yup.string(),
      referralType: Yup.string(),
      referralName: Yup.string(),
      excludedFromCalculations: Yup.boolean(),
    }),
    shippingAddress: Yup.object({
      street: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }).nullable(),
  }).default({}),
  privateUserData: Yup.object({
    isFreeRider: Yup.boolean().default(false),
  }).default({}),
}).required()
